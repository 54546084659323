import React from "react"
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { RootState } from "../../../slices"
import { fetchInvestorTypes } from "../slices/investorTypes"
import { fetchInvestorInterests } from "../slices/investorInterests"
import { fetchInvestors, setPage, setInitialState } from "../slices/investors"
import { InvestorFilters } from "../components/InvestorFilters"
import { InvestorHighlights } from "../components/InvestorHighlights"
import { ActionsTopMenu } from "../components/ActionsTopMenu"
import { TableSwitch } from "../../../core/components/TableSwitch"
import { TablePagination } from "../../../core/components/TablePagination"

import { InvestorTable } from "../components/InvestorTable"
import { InvestorChart } from "../components/InvestorTableChart"

import { Loader } from "../../../core/components/Loader"

const InvestorsListPage = () => {
  const dispatch = useDispatch()

  const { 
    investorTypes 
  } = useSelector((state: RootState) => state.investorTypes)
  const {
    results: investors,
    loading,
    error,
    next,
    previous,
    count,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.investors)
  const [tableMode, setTableMode] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const componentWillUnmount = useRef(false)

  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleReset = () => {
    dispatch(setInitialState())
  }

  useEffect(() => {
    componentWillUnmount.current = true
    dispatch(fetchInvestorTypes())
    dispatch(fetchInvestorInterests())
    dispatch(fetchInvestors({ page, sort, filters }))
  }, [dispatch, page, sort, filters])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) dispatch(setInitialState())
    }
  }, [dispatch])

 if (loading) return <Loader />

  return (
    <div className='pageWrapper investorListPage'>
      <div className='pageBodyTopWrapper'>
        <ActionsTopMenu
          newModelName='Investor'
        />

        <div className='filtersDesktopWrapper'>
          <InvestorFilters 
            filters = { filters ?? {} }
            investorTypes = { investorTypes }
          />
        </div>
        <div className='filtersMobileWrapper'>
          <Accordion
            expanded = { expanded === "panel1" }
            onChange = { handleChange("panel1") }
            elevation = { 0 }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorFilters 
                filters = { filters ?? {} }
                investorTypes = { investorTypes }
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <InvestorHighlights />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
          <TableSwitch
            active={tableMode}
            onChanged={() => setTableMode(!tableMode)}
          />

          {error && <div className='warningMessageWrapper'>
            <p>Request failed: {error}</p>
            <br />
            <button onClick={handleReset}>Try again</button>
          </div>}

          {!error && !investors.length && <p className="warningMessageWrapper">No investors found.</p>}

          {investors.length !== 0 && !error && (
            <>
              <div className={`table${!tableMode ? "Active" : "Hidden"}`}>
                <InvestorTable investors={investors} sort={sort} />
              </div>

              <div className={`table${tableMode ? "Active" : "Hidden"}`}>
                <InvestorChart 
                  investors={investors} 
                  maxValue={ Math.max(...investors.map(o => o.totalSubscribed), 0) }
                />
              </div>

              <TablePagination
                next={next}
                onNext={handleNext}
                onPrevious={handlePrevious}
                previous={previous}
                page={page}
                count={count ?? 0}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvestorsListPage
