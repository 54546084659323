import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"

import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import {
  AllocationList,
  TableMode,
} from "../../allocations/components/AllocationList"
import InvestmentDetails from "../components/InvestmentDetails"
import { fetchInvestment } from "../slices/investment"
import { setInitialState } from "../../allocations/slices/allocations"
import { fetchInvestmentTypes } from "../slices/investmentTypes"
import { ActionsBottomMenu } from "../components/ActionsBottomMenu"

interface ParamType {
  id: string
}

const InvestmentPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams<ParamType>()
  const { loading, investment, error } = useSelector(
    (state: RootState) => state.investment
  )

  const componentWillUnmount = useRef(false)

  const refreshPage = () => {
    window.location.reload() 
  }

  useEffect(() => {
    componentWillUnmount.current = true
    dispatch(fetchInvestment({ id }))
    dispatch(fetchInvestmentTypes())
  }, [dispatch])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) dispatch(setInitialState())
    }
  }, [dispatch])

  if (loading || investment == null) return <Loader />

  if (error) return <div className="errorMessageWrapper">
    Something went wrong...
    <br/>
    <button onClick={refreshPage}>
      Reload page
    </button>
  </div>

  return (
    <div className='pageWrapper investmentPage'>
      <div className='pageBodyTopWrapper'>
        <InvestmentDetails />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
          <ActionsBottomMenu
            investment={investment}
          />
          <AllocationList mode={TableMode.Investment} investment={investment} />
        </div>
      </div>
    </div>
  )
}

export default InvestmentPage
