import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Investor } from "../models/investor"
import { baseHeaders, processResponse } from "../../../helpers/requests"

import { keysToSnake } from "../../../helpers/toSnake"

interface InvestorState {
  investor?: Investor
  loading: boolean
  error?: string
}
interface IdProp {
  id?: string
  values?: any
}

const initialState: InvestorState = {
  loading: true,
  error: undefined
}

// Async
export const fetchInvestor = createAsyncThunk(
  "investment/fetchInvestor",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .get(`${process.env.REACT_APP_API_HOST}users/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const updateInvestor = createAsyncThunk(
  "investment/updateInvestor",
  async ({ id, values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_HOST}users/${id}/`,
        {
          ...keysToSnake({...params, ...{'username': params.email }})
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const createInvestor = createAsyncThunk(
  "investment/createInvestor",
  async ({ values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}users/`,
        {
          ...keysToSnake({...params, ...{'username': params.email }})
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 201) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const deleteInvestor = createAsyncThunk(
  "investment/deleteInvestor",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .delete(`${process.env.REACT_APP_API_HOST}users/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investorSlice = createSlice({
  name: "investor",
  initialState,
  reducers: {
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    resetInvestorError: state => {
      state.error = undefined
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestor.fulfilled, (state, { payload }) => {
      state.investor = payload
      state.loading = false
    })
    addCase(fetchInvestor.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(updateInvestor.fulfilled, (state, { payload }) => {
      state.investor = payload
      state.loading = false
    })
    addCase(updateInvestor.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(createInvestor.fulfilled, (state, { payload }) => {
      state.investor = payload
      state.loading = false
    })
    addCase(createInvestor.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(deleteInvestor.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    addCase(deleteInvestor.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading, resetInvestorError } = investorSlice.actions

export default investorSlice.reducer
