import React, { ChangeEventHandler } from "react"
import NumberFormat from "react-number-format"
/* Select */
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
/* Textarea */
import TextField from "@material-ui/core/TextField"
/* Common */
import FormControl from "@material-ui/core/FormControl"

interface NumberFormatCustomPropTypes {
  inputRef: ((el: HTMLInputElement) => void) | React.Ref<any>
  name: string
  onChange: Function
}

const NumberFormatCustom = (props: NumberFormatCustomPropTypes) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

interface FormInputProps {
  id?: string
  htmlFor?: string
  label?: string
  type:
    | "text"
    | "password"
    | "number"
    | "quantity"
    | "textarea"
    | "checkbox"
    | "radio"
    | "select"
    | "multipleSelect"
    | "date"
  handleChange: ChangeEventHandler
  placeholder?: string
  values?: string | number | readonly string[]
  checked?: boolean
  min?: number
  disabled?: boolean
  errors?: string
  touched?: boolean
  arrayOfValues?: any
}

export const FormInput = (props: FormInputProps) => {
  const {
    id,
    placeholder,
    type,
    values,
    min,
    disabled,
    handleChange,
    errors,
    touched,
    arrayOfValues,
  } = props

  switch (type) {
    case "text":
    case "password":
      return (
        <div className={id + "Wrapper"}>
          <input
            id={id}
            placeholder={placeholder}
            type={type}
            value={values}
            disabled={disabled}
            onChange={handleChange}
            className={errors && touched ? "text-input error" : "text-input"}
          />
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    case "number":
      return (
        <div className={id + "Wrapper"}>
          <input
            id={id}
            placeholder={placeholder}
            type={type}
            defaultValue={values}
            min={min}
            onChange={handleChange}
            className={errors && touched ? "text-input error" : "text-input"}
          />
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    case "quantity":
      return (
        <div className={id + "Wrapper"}>
          <TextField
            id={id}
            name={id}
            placeholder={placeholder}
            defaultValue={values}
            onChange={handleChange}
            variant='outlined'
            className={errors && touched ? "textarea error" : "textarea"}
            inputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    case "textarea":
      return (
        <div className={id + "Wrapper"}>
          <TextField
            id={id}
            label='Bio'
            multiline
            rows={3}
            defaultValue={values}
            onChange={handleChange}
            variant='outlined'
            className={errors && touched ? "textarea error" : "textarea"}
          />
          {errors && touched && <div className='input-feedback countCharactersMargin'>{errors}</div>}
          <div className="countCharacters">{String(values).length}/250</div>
        </div>
      )
    case "select":
      return (
        <div className={id + "Wrapper"}>
          <FormControl variant='outlined'>
            <InputLabel id='selectID'>{placeholder}</InputLabel>
            <Select
              labelId='selectID'
              id={id}
              value={values}
              onChange = {(e: any) => {
                e.target.id = id
                handleChange(e)
              }}
              label={placeholder}
              className={errors && touched ? "select error" : "select"}
            >
              {Object.entries(arrayOfValues).map(([key, value]: [string, any]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    case "multipleSelect":
      return (
        <div className={id + "Wrapper"}>
          <FormControl variant='outlined'>
            <InputLabel id='selectID'>{placeholder}</InputLabel>
            <Select
              labelId='selectID'
              id={id}
              multiple
              value={values}
              onChange = {(e: any) => {
                e.target.id = id
                handleChange(e)
              }}
              label={placeholder}
              className={errors && touched ? "select error" : "select"}
            >
              {Object.entries(arrayOfValues).map(([key, value]: [string, any]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    case "date":
      return (
        <div className={id + "Wrapper"}>
          <TextField
            id={id}
            label={placeholder}
            type='date'
            defaultValue={values}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={handleChange}
            className={errors && touched ? "text-input error" : "text-input"}
          />
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
    default:
      return (
        <div className={id + "Wrapper"}>
          <input
            id={id}
            placeholder={placeholder}
            type={type}
            defaultValue={values}
            disabled={disabled}
            onChange={handleChange}
            className={errors && touched ? "text-input error" : "text-input"}
          />
          {errors && touched && <div className='input-feedback'>{errors}</div>}
        </div>
      )
  }
}
