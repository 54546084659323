import { CompanyListItemChart } from "./CompanyListItemChart"
import { Company } from "../models/company"
import { xAxisTable } from "../../../helpers/xAxisTableChart"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"

interface IProps {
  companies: any
  maxValue: number
}

interface Props {
  company: Company
}

export const CompanyChart = ({ companies,maxValue }: IProps) => {
  return (
    <div className='tableCompanyListChart'>
      <div className='grid-table'>
        <div className='grid-table-row'>
          <div className='grid-table-cell'>Company</div>
          <div className='grid-table-cell'>
            { xAxisTable(maxValue).map((x: number) => (
              <span className='yaxis' key={x}>
                { formatCurrency(x, true, true, true) }
              </span>
            )) }
          </div>
        </div>

        {companies.map((company: Company) => (
          <CompanyListItemChart 
            key={company.id} 
            company={company}  
            maxValueWeight={String(Math.trunc( maxValue )).length}/>
        ))}
      </div>
    </div>
  )
}
