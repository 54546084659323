import { numberWithCommas } from "./formatNumberCommas"

export const formatNumber = (value: number) => {
  if (value === null) return "0"

  return numberWithCommas(value)
}

export const formatCurrency = (value: number = 0, round: boolean = true, zero: boolean = false, natural: boolean = false) => {
  let sign = ""
  if (value === 0) return zero ? "0" : "-"

  if (value < 0) sign = "-"

  if (!round)
    return `${sign}£${value.toLocaleString("en-GB").toString().replace(/\B(?=(\d{3})+(?!\d))/g, "")}`

  if (value >= 1000000)
    return `${sign}£${(Math.abs(value) / 1000000).toFixed(natural ? 0 : 2)}m`

  if (value >= 1000)
    return `${sign}£${(Math.abs(value) / 1000).toFixed(natural ? 0 : 2)}k`
  
  return `${sign}£${Math.abs(value).toFixed(natural ? 0 : 2)}`
}
