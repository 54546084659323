import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { InvestmentListItem } from "./InvestmentListItem"
import { Investment } from "../models/investment"
import { TableColumn } from "../../../core/models/tables"
import { TableHeader } from "../../../core/components/TableHeader"
import { Sort } from "../../../core/models/sort"
import { setSort } from "../slices/investments"

interface IProps {
  investments: Investment[]
  sort: Sort
}

const investmentTableColumns: TableColumn[] = [
  { value: "" },
  { value: "Company", field: "company__name" },
  { value: "Amount Invested", field: "currently_allocated" },
  { value: "Current Value" },
  { value: "State", field: "state__order" },
  { value: "Tax Relief", field: "tax_relief__order" },
  { value: "Investment Date", field: "target_investment_date" },
  { value: "" },
]

export const InvestmentTable = ({ investments, sort }: IProps) => {
  const dispatch = useDispatch()

  const { 
    loadingTable
  } = useSelector((state: RootState) => state.investments)

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  if (loadingTable) return <Loader />

  return (
    <div className='tableInvestmentList'>
      <div className='grid-table investment-table'>
        <div className='grid-table-row'>
          {investmentTableColumns.map((column: TableColumn, index: number) => (
            <TableHeader
              key={`investment-table-header-${index}`}
              value={column.value}
              field={column.field}
              sort={sort}
              onSort={handleSort}
            />
          ))}
        </div>

        {investments.map((investment: Investment) => (
          <InvestmentListItem
            key={`table-row-${investment.id}`}
            investment={investment}
          />
        ))}
      </div>
    </div>
  )
}
