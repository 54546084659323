import { useSelector } from "react-redux"
import { SummaryItem } from "../../../core/components/SummaryItem"
import { SummaryItemWithSubtitles } from "../../../core/components/SummaryItemWithSubtitles"
import { formatCurrency } from "../../../helpers/numbers"
import { RootState } from "../../../slices"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"

export const CompanyHighlights = () => {
  const {
    count,
    totalInvested,
    totalInvestable,
    totalSubscribed,
  } = useSelector((state: RootState) => state.companies)

  return (
    <div className='highlights'>
      <SummaryItem
        title='Number of Companies'
        value={`${count ?? "-"}`}
        className='column1'
      />
      <SummaryItem
        title='Total Invested'
        type={AttributeType.Currency}
        value={totalInvested ?? 0.0}
        className='column2'
      />
      <SummaryItemWithSubtitles
        title='Portfolio'
        subtitles={{
          Value: "-", // "0.0"
          TVPI: "-", // "1.0x"
          IRR: "-", // "0.0%"
        }}
        className='column3'
      />
    </div>
  )
}
