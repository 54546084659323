import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"

import { Type } from "../../../core/models/type"

import { AttributeItem, AttributeType } from "../../../core/components/AttributeItem"
import { TextButton } from "../../../core/components/TextButton"

import { createInvestor, resetInvestorError } from "../../../features/investors/slices/investor"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { Formik } from "formik"
import * as Yup from "yup"


interface IProps {
  newModelName: string
}


function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}


export const ActionsTopMenu = ({ newModelName }: IProps) => {
  const dispatch = useDispatch()

  const { 
    loading, 
    // investor, 
    error 
  } = useSelector((state: RootState) => state.investor)

  const { 
    investorTypes 
  } = useSelector((state: RootState) => state.investorTypes)

  const { 
    investorInterests
  } = useSelector((state: RootState) => state.investorInterests)

  const [openModal, setOpenModal] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)

  const handleClickOpenModal = () => {
    setOpenModal(true)
  }
  const handleClickCloseModal = () => {
    setOpenModal(false)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleFormSubmit = (values: any) => {
    dispatch(createInvestor({ values }))
  }

  useEffect(() => {
    if (!loading) {
      if (error === 'Rejected') handleClickAlert()
      handleClickCloseModal()
    }
    dispatch(resetInvestorError())
  }, [loading, error])

  return (
    <div className='actionsTopMenu'>
      <div className='addNewButton'>
        <a onClick={handleClickOpenModal}>
          + Add New {newModelName}
        </a>
      </div>
      
      {/*
      <div className='exportCSV'>
        <a>Export CSV</a>
      </div>
      */}

      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          There was a network error. Please try again later.
        </Alert>
      </Snackbar>

      <Dialog open={openModal} onClose={handleClickCloseModal} aria-labelledby="form-dialog-title" className='actionsTopMenuInvestors' maxWidth="lg">

        <DialogTitle id="form-dialog-title">New Investor</DialogTitle>

        {
          loading 
          ? <Loader classStyle="modalWindow"/>
          : <Formik
              initialValues = {{
                // photo: string,
                firstName: '',
                lastName: '',
                bio: '',
                phone: '',
                email: '',
                cobs: '',
                interests: [],
                company: '',
                investorType: 1,
                linkedin: '',
                addressStreet: '',
                addressCity: '',
                addressCountry: '',
                addressPostcode: '',
                // >> approved: boolean,
                // >> isStaff: boolean,
              }}
              onSubmit = {values => {
                handleFormSubmit (values)
              }}
              validationSchema = {Yup.object().shape({
                firstName: Yup.string().required('This is a required field'),
                lastName: Yup.string().required('This is a required field'),
                bio: Yup.string(),
                phone: Yup.string(),
                email: Yup.string().email().required('This is a required field').typeError("Must be a valid email"),
                cobs: Yup.string(),
                interests: Yup.array(),
                company: Yup.string().required('This is a required field'),
                investorType: Yup.number().typeError("Must be a number"),
                linkedin: Yup.string(),
                addressStreet: Yup.string(),
                addressCity: Yup.string(),
                addressCountry: Yup.string(),
                addressPostcode: Yup.string()
              })}
            >
              { props => {
                const {
                  values,
                  touched,
                  errors,
                  // isSubmitting,
                  handleChange,
                  handleSubmit
                } = props
                return (
                  <form>
                    <DialogContent>
                    <div className='formContainer attributesContainer'>
                      <div className='columnsWrap'>
                        <div className='columnLeft'>
                          <AttributeItem
                            header='First Name'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'firstName'
                            values = {values.firstName}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Last Name'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'lastName'
                            values = {values.lastName}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Bio'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'bio'
                            values = {values.bio}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Phone'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'phone'
                            values = {values.phone}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Email'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'email'
                            values = {values.email}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Interests'
                            value={[]}
                            type={AttributeType.Array}
                            readOnly={false}
                            inputType='multipleSelect'
                            item = 'interests'
                            values = {values.interests}
                            arrayOfValues = {
                              investorInterests?.reduce<Record<string, number>>(function (obj, item: Type) {
                                obj[item.name] = item.id
                                return obj
                              }, {})
                            }
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Company'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'company'
                            values = {values.company}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                        </div>

                        <div className='columnRight'>
                          <AttributeItem
                            header='Address Street'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'addressStreet'
                            values = {values.addressStreet}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Address City'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'addressCity'
                            values = {values.addressCity}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Address Country'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'address Country'
                            values = {values.addressCountry}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Address Postcode'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'addressPostcode'
                            values = {values.addressPostcode}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Type'
                            value={''}
                            type={AttributeType.Number || AttributeType.Null}
                            readOnly={false}
                            inputType='select'
                            item = 'investorType'
                            values = {values.investorType}
                            arrayOfValues = {
                              investorTypes?.reduce<Record<string, number>>(function (obj, item: Type) {
                                obj[item.name] = item.id
                                return obj
                              }, {})
                            }
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='COBS'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'cobs'
                            values = {values.cobs}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          <AttributeItem
                            header='Social Media'
                            value={''}
                            type={AttributeType.Text}
                            readOnly={false}
                            item = 'linkedin'
                            values = {values.linkedin}
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                        </div>
                      </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <TextButton onClick={handleClickCloseModal}>
                        <span>Cancel</span>
                      </TextButton>
                      <TextButton onClick={handleSubmit}>
                        <button type="submit">Submit</button>
                      </TextButton>
                    </DialogActions>
                  </form>
                )
              }}
            </Formik>
        }
      </Dialog>
    </div>
  )
}
