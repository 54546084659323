import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { getInvestorName } from "../../../helpers/strings"
import { RootState } from "../../../slices"
import { CompanyInvestment } from "../models/portfolio"
import { getSectors } from "../../../helpers/companies"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  companyInvestment: CompanyInvestment
}

export const PortfolioListItem = ({ companyInvestment }: Props) => {
  return (
    <div className='grid-table-row list-item portfolio-list-item'>
      <div className='grid-table-cell' data-title='Company Logo'>
        {companyInvestment.company?.logo && (
          <img className='company-logo' src={companyInvestment.company.logo} />
        )}
      </div>
      <div className='grid-table-cell company' data-title='Company'>
        <Link to={`/investments/${companyInvestment.id}`}>
          <p>{companyInvestment.company.name}</p>
          <p>
            {companyInvestment.company && getSectors(companyInvestment.company)}
          </p>
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Total Invested'>
        <TooltipCurrency amount={companyInvestment.totalInvestment  ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Current Value'>
        <TooltipCurrency amount={companyInvestment.totalInvestment ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Growth'>
        {formatNumber(companyInvestment.growth ?? 0.0)}
      </div>
    </div>
  )
}
