import { useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { formatNumber, formatCurrency } from "../../../helpers/numbers"
import { RootState } from "../../../slices"
import { Company } from "../models/company"
import { AttributeItem, AttributeType } from "../../../core/components/AttributeItem"

interface IProps {
  company?: Company
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const CompanySummary = ({
  company,
  readOnly = true,
  values,
  handleChange,
  errors,
  touched,
}: IProps) => {
  const { types } = useSelector((state: RootState) => state.companyTypes)

  return (
    <div className='summaryContainer'>
      <div className='highlights'>
        <AttributeItem
          header='Current Valuation'
          value={company?.currentValuation ?? 0.0}
          type={AttributeType.Currency}
          inputType='text'
          className='highlightsColumn column1'
          readOnly={readOnly}
          item='currentValuation'
          values={values.currentValuation}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Amount Invested'
          value={company?.amountInvested ?? 0.0}
          type={AttributeType.Currency}
          inputType='text'
          className='highlightsColumn column2'
          readOnly={readOnly}
          item = 'amountInvested'
          values = {values.amountInvested}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
      </div>
    </div>
  )
}
