import { FunctionComponent } from "react"

interface IProps {
  onClick: () => void
}

export const TextButton: FunctionComponent<IProps> = ({
  children,
  onClick,
}) => {
  return (
    <div className='textButton' onClick={onClick}>
      {children}
    </div>
  )
}
