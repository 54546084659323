import { Link } from "react-router-dom"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { getInvestorName } from "../../../helpers/strings"
import { Investor } from "../models/investor"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  investor: Investor
}

export const InvestorListItem = ({ investor }: Props) => {
  return (
    <div className='grid-table-row listItem investorListItem'>
      <div className='grid-table-cell' data-title='Investor'>
        <Link to={`/investors/${investor.id}`}>
          {getInvestorName(investor)}
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Total Subscription'>
        <TooltipCurrency amount={investor.totalSubscribed ?? 0} />
      </div>
      <div
        className='grid-table-cell totalInvested'
        data-title='Total Invested'
      >
        <TooltipCurrency amount={investor.totalInvested ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Total Investable'>
        <TooltipCurrency amount={investor.totalInvestable ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Current Value'>
        <TooltipCurrency amount={investor.totalInvested ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='TVPI'>
        -
        {/*formatNumber(investor.tvpi)*/}
      </div>
      <div className='grid-table-cell' data-title='IRR'>
        -
        {/*formatNumber(investor.irr)*/}
      </div>
      <div className='grid-table-cell' data-title=''>
        <Link to={`/investors/${investor.id}`}>View more</Link>
      </div>
    </div>
  )
}
