import { Investor } from "../features/investors/models/investor"

export const capitalise = (word: string) => {
  return word[0].toUpperCase() + word.substring(1)
}

export const getInvestorName = (investor?: Investor) => {
  if (!investor) return ""
  return `${capitalise(investor.firstName)} ${capitalise(investor.lastName)}`
}
