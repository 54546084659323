import { Link } from "react-router-dom"
import { Company } from "../models/company"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  company: Company
}

export const CompanyListItem = ({ company }: Props) => {
  return (
    <div className='grid-table-row listItem companyListItem'>
      <div className='grid-table-cell companyLogo' data-title=''>
        {company?.logo && (
          <img className='company-logo' src={company.logo} />
        )}
      </div>
      <div className='grid-table-cell' data-title='Company'>
        <Link to={`/companies/${company.id}`}>
          {company.name}
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Valuation'>
        <TooltipCurrency amount={company.currentValuation ?? 0} />
      </div>
      <div className='grid-table-cell totalInvested' data-title='Amount Invested'>
        <TooltipCurrency amount={company.amountInvested ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='GMOIC'>
        {company.gmoic}
      </div>
      <div className='grid-table-cell' data-title='IRR'>
        {company.irr}
      </div>
      <div className='grid-table-cell' data-title='Investment date'>
        {company.firstInvestmentDate ?? '-'}
      </div>
      <div className='grid-table-cell' data-title=''>
        <Link to={`/companies/${company.id}`}>View more</Link>
      </div>
    </div>
  )
}
