import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"

import { Type } from "../../../core/models/type"

import { AttributeItem, AttributeType } from "../../../core/components/AttributeItem"
import { TextButton } from "../../../core/components/TextButton"

import { createCompany, resetCompanyError } from "../../../features/companies/slices/company"
import { fetchCompanies } from "../slices/companies"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { Formik } from "formik"
import * as Yup from "yup"


interface IProps {
  newModelName: string
}


function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}


export const ActionsTopMenu = ({ newModelName }: IProps) => {
  const dispatch = useDispatch()
  const {
    results: companies,
    error,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.companies)
  const { 
    loading, 
    // company, 
    // error 
  } = useSelector((state: RootState) => state.company)

  const { 
    types 
  } = useSelector((state: RootState) => state.companyTypes)

  const { 
    companySectors
  } = useSelector((state: RootState) => state.companySectors)

  const [openModal, setOpenModal] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)

  const handleClickOpenModal = () => {
    setOpenModal(true)
  }
  const handleClickCloseModal = () => {
    setOpenModal(false)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleFormSubmit = (values: any) => {
    dispatch(createCompany({ values }))
    dispatch(fetchCompanies({ page, sort, filters }))
  }

  useEffect(() => {
    if (!loading) {
      if (error === 'Rejected') handleClickAlert()
      handleClickCloseModal()
    }
    dispatch(resetCompanyError())
  }, [loading, error])

  return (
    <div className='actionsTopMenu'>
      <div className='addNewButton'>
        <a onClick={handleClickOpenModal}>
          + Add New {newModelName}
        </a>
      </div>
      
      {/*
      <div className='exportCSV'>
        <a>Export CSV</a>
      </div>
      */}

      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          There was a network error. Please try again later.
        </Alert>
      </Snackbar>

      <Dialog open={openModal} onClose={handleClickCloseModal} aria-labelledby="form-dialog-title" className='actionsTopMenuCompanies' maxWidth="lg">

        <DialogTitle id="form-dialog-title">New Company</DialogTitle>

        {
          loading 
          ? <Loader classStyle="modalWindow"/>
          : <Formik
              initialValues = {{
                name: '',
                // amountInvested: '',
                // gmoic: '',
                // irr: '',
                firstInvestmentDate: '',
                // currentValuation: '',
                sectors: [],
                domicile: '',
                registrationNumber: ''
              }}
              onSubmit = {values => {
                handleFormSubmit (values)
              }}
              validationSchema = {Yup.object().shape({
                name: Yup.string().required('This is a required field'),
                // amountInvested: Yup.number().required('This is a required field'),
                // gmoic: Yup.number().typeError("Must be a number").required('This is a required field'),
                // irr: Yup.number().typeError("Must be a number").required('This is a required field'),
                firstInvestmentDate: Yup.date().typeError("Must be a date").required('This is a required field'),
                // currentValuation: Yup.number().required('This is a required field'),
                sectors: Yup.array(),
                domicile: Yup.string(),
                registrationNumber: Yup.number().typeError("Must be a number").required('This is a required field')
              })}
            >
              { props => {
                const {
                  values,
                  touched,
                  errors,
                  // isSubmitting,
                  handleChange,
                  handleSubmit
                } = props
                return (
                  <form>
                    <DialogContent>
                    <div className='formContainer attributesContainer'>
                      <div className='columnsWrap'>
                        <div className='columnLeft'>
                          <AttributeItem
                            header='Name'
                            value={''}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='name'
                            values={values.name}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Registration Number'
                            value={''}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='registrationNumber'
                            values={values.registrationNumber}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Incorporation date '
                            value={''}
                            type={AttributeType.Date}
                            inputType='date'
                            readOnly={false}
                            item='firstInvestmentDate'
                            values={values.firstInvestmentDate}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          {/*
                          <AttributeItem
                            header='Amount Invested'
                            value={''}
                            type={AttributeType.Number}
                            inputType='text'
                            readOnly={false}
                            item='amountInvested'
                            values={values.amountInvested}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Current Valuation'
                            value={''}
                            type={AttributeType.Number}
                            inputType='text'
                            readOnly={false}
                            item='currentValuation'
                            values={values.currentValuation}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          */}
                        </div>

                        <div className='columnRight'>
                          <AttributeItem
                            header='Location'
                            value={''}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='domicile'
                            values={values.domicile}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Sectors'
                            value={[]}
                            type={AttributeType.Number || AttributeType.Null}
                            inputType='multipleSelect'
                            readOnly={false}
                            item = 'sectors'
                            values = {values.sectors}
                            arrayOfValues = {
                              companySectors?.reduce<Record<string, number>>(function (obj, item: Type) {
                                obj[item.name] = item.id
                                return obj
                              }, {})
                            }
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />
                          {/*
                          <AttributeItem
                            header='GMOIC'
                            value={''}
                            type={AttributeType.Number}
                            inputType='text'
                            readOnly={false}
                            item='gmoic'
                            values={values.gmoic}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='IRR'
                            value={''}
                            type={AttributeType.Number}
                            inputType='text'
                            readOnly={false}
                            item='irr'
                            values={values.irr}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          */}
                        </div>
                      </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <TextButton onClick={handleClickCloseModal}>
                        <span>Cancel</span>
                      </TextButton>
                      <TextButton onClick={handleSubmit}>
                        <button type="submit">Submit</button>
                      </TextButton>
                    </DialogActions>
                  </form>
                )
              }}
            </Formik>
        }
      </Dialog>
    </div>
  )
}
