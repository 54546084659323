import { isArray } from "./isArray"
import { isObject } from "./isObject"

const toSnake = (s: string): string => {
  return s.replace(/([A-Z])/g, $1 => {
    return `_${$1.toLowerCase()}`
  })
}

interface LooseObject {
  [key: string]: any
}

export const keysToSnake = function (o: any) {
  if (isObject(o)) {
    const n: LooseObject = {}

    Object.keys(o).forEach((k: string) => {
      n[toSnake(k)] = keysToSnake(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: number) => {
      return keysToSnake(i)
    })
  }

  return o
}