import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"

import { RootState } from "../../../slices"
import { InvestorDescription } from "./InvestorDescription"
import { InvestorSummary } from "./InvestorSummary"
import { InvestorAttributes } from "./InvestorAttributes"

import { updateInvestor, deleteInvestor } from "../slices/investor"

import { Formik } from "formik"
import * as Yup from "yup"

import { TextButton } from "../../../core/components/TextButton"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface ParamType {
  id: string
}

const InvestorDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const investor = useSelector(
    (state: RootState) => state.investor.investor
  )
  const [readOnly, setReadOnly] = useState(true)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setReadOnly(true)
    setOpen(true)
  }

  const handleClose = () => {
    setReadOnly(false)
    setOpen(false)
  }

  const onDeleteInvestor = () => {
    history.push("/investors")
    dispatch(deleteInvestor({ id: id }))
  }

  const getTextButtonChild = () => {
    return readOnly ? (
      <span>Edit Investor</span>
    ) : (
      <div className="actionsButtonsWrap">
        <button type="submit">
          Save Investor
        </button>
        <button className={`buttonDelete ${readOnly ? 'hidden': ''}`} onClick={e => {
          e.preventDefault()
          handleClickOpen()
        }}>
          Delete Investor
        </button>
      </div>
    )
  }

  return (
    <>
    <Formik
      initialValues = {{
        firstName: investor?.firstName,
        lastName: investor?.lastName,
        bio: investor?.bio,
        phone: investor?.phone || '',
        email: investor?.email,
        cobs: investor?.cobs,
        interests: investor?.interests || [],
        company: investor?.company,
        investorType: investor?.investorType,
        linkedin: investor?.linkedin,
        addressStreet: investor?.addressStreet,
        addressPostcode: investor?.addressPostcode,
        addressCity: investor?.addressCity,
        addressCountry: investor?.addressCountry
      }}
      onSubmit = {values => {
        if (!readOnly) {
          dispatch(updateInvestor({ id, values }))
        }
        setReadOnly(!readOnly)
      }}
      validationSchema = {Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        bio: Yup.string().test('len', 'Too many characters', val => String(val).length < 250),
        phone: Yup.string(),
        email: Yup.string().email().typeError("Must be a valid email"),
        cobs: Yup.string(),
        interests: Yup.array(),
        company: Yup.string(),
        // investorType: Yup.number().typeError("Must be a number"),
        linkedin: Yup.string(),
        addressStreet: Yup.string(),
        addressPostcode: Yup.string(),
        addressCity: Yup.string(),
        addressCountry: Yup.string()
      })}
    >
      { props => {
        const {
          values,
          touched,
          errors,
          // isSubmitting,
          handleChange,
          handleSubmit
        } = props
        return (
          <form>
            <div className='header'>
            <InvestorDescription
              investor={investor}
              readOnly={readOnly}
              values = {values}
              handleChange = {handleChange}
              errors = {errors}
              touched = {touched}
            />
            <InvestorSummary investor={investor} readOnly={readOnly}/>
            </div>
            <InvestorAttributes 
              investor={investor}
              readOnly={readOnly}
              values = {values}
              handleChange = {handleChange}
              errors = {errors}
              touched = {touched}
            />
            <TextButton onClick={handleSubmit}>
              {getTextButtonChild()}
            </TextButton>
          </form>
        )
      }}
    </Formik>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this investor?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This investor will be deleted inmediately.<br/>You can't undo this action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={handleClose}>
          <b>Cancel</b>
        </TextButton>
        <button 
          className={`buttonDelete small`}
          onClick={onDeleteInvestor}
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default InvestorDetails
