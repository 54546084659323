import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { InvestorListItem } from "./InvestorListItem"
import { Investor } from "../models/investor"
import { setSort } from "../slices/investors"
import { Sort } from "../../../core/models/sort"

import { TableColumn } from "../../../core/models/tables"
import { TableHeader } from "../../../core/components/TableHeader"

interface IProps {
  investors: any
  sort: Sort
}

const investorTableColumns: TableColumn[] = [
  { value: "Investor", field: "first_name,last_name" },
  { value: "Total Subscription", field: "total_subscribed" },
  { value: "Total Invested", field: "total_invested" },
  { value: "Total Investable", field: "total_investable" },
  { value: "Current Value", field: "current_value" },
  { value: "TVPI", field: "tvpi" },
  { value: "IRR", field: "irr" },
  { value: "" },
]

export const InvestorTable = ({ investors, sort }: IProps) => {
  const dispatch = useDispatch()

  const { 
    loadingTable
  } = useSelector((state: RootState) => state.investors)

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  if (loadingTable) return <Loader />

  return (
    <div className='tableInvestorList'>
      <div className='grid-table'>
        <div className='grid-table-row'>
          {investorTableColumns.map((column: TableColumn, index: number) => (
            <TableHeader
              key={`investment-table-header-${index}`}
              value={column.value}
              field={column.field}
              sort={sort}
              onSort={handleSort}
            />
          ))}
        </div>

        {investors.map((investor: Investor) => (
          <InvestorListItem key={investor.id} investor={investor} />
        ))}
      </div>
    </div>
  )
}
