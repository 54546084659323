import React from "react"
import FormGroup from "@material-ui/core/FormGroup"
import Switch from "@material-ui/core/Switch"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

interface IProps {
  labels?: string[]
  active: boolean
  onChanged: Function
}

export const TableSwitch = ({ labels = ['TABLE', 'CHART'], active, onChanged }: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // event.preventDefault()
    onChanged()
  }

  const isChart = (chart: boolean) => {
    if (chart) return (
    <div className={`tableSwitchlabels table${active ? "Chart" : ""}`}>
      <div className="investedLabel">
        <span></span> Invested
      </div>
      <div className="investedValue">
        <span></span> Value
      </div>
    </div>
    )
  }

  return (
    <div className='tableSwitchWrap'>
      <div className='tableSwitch investorTableSwitch'>
        <FormGroup>
          <Typography component='div'>
            <Grid component='label' container alignItems='center' spacing={1}>
              <Grid item>{ labels[0] }</Grid>
              <Grid item>
                <Switch
                  checked={active}
                  onChange={handleChange}
                  name='checkedA'
                />
              </Grid>
              <Grid item>{ labels[1] }</Grid>
            </Grid>
          </Typography>
        </FormGroup>
      </div>
      { isChart (labels[1] === 'CHART') }
    </div>
  )
}
