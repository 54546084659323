import { Link } from "react-router-dom"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { Company } from "../models/company"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  company: Company
  maxValueWeight: number
}

export const CompanyListItemChart = ({ company, maxValueWeight }: Props) => {
  return (
    <div className='grid-table-row listItem companyListItemChart'>
      <div className='grid-table-cell' data-title='Company'>
        <Link to={`/companies/${company.id}`}>
          {company.name}
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Total Subscription'>
        <div className='barsWrapper'>
          <div className='bar barInvested' style={{ width: company.amountInvested/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={company.amountInvested ?? 0} />
            </div>
          </div>
          <div className='bar barValue' style={{ width: company.currentValuation/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={company.currentValuation ?? 0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
