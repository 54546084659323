import { Route, Redirect, RouteProps } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../slices"

export const ProtectedRoute = (props: RouteProps) => {
  const { user } = useSelector((state: RootState) => state.auth)

  if (user) return <Route {...props} />

  return (
    <Redirect
      to={{
        pathname: "/",
        state: {
          from: props.location,
        },
      }}
    />
  )
}
