import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { getInvestorName } from "../../../helpers/strings"
import { RootState } from "../../../slices"
import { Application } from "../models/application"
import { getSectors } from "../../../helpers/companies"
import { getTypeById } from "../../../helpers/types"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  application: Application
  // onViewMore: Function
}

export const ApplicationListItem = ({ application }: Props) => {
  const { investor } = useSelector((state: RootState) => state.investor)
  const { types } = useSelector((state: RootState) => state.applicationTypes)

  return (
    <div className='grid-table-row list-item allocation-list-item'>
      <div className='grid-table-cell' data-title='Investment Amount'>
        <TooltipCurrency amount={application.investmentAmount ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Funds Cleared'>
        {application.fundsClearedDate ?? " - "}
      </div>
      <div className='grid-table-cell' data-title='AML'>
        {application.amlDate ?? " - "}
      </div>
      <div className='grid-table-cell' data-title='State'>
        {getTypeById(types?.applicationState, application.state)?.name ?? " - "}
      </div>
      <div
        // onClick={handleViewMore}
        className='grid-table-cell'
        data-title=''
      >
        <Link
          to={`/investors/${investor?.id ?? 0}/applications/${application.id}`}
        >
          View More
        </Link>
      </div>
    </div>
  )
}
