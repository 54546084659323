import { useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { formatNumber, formatCurrency } from "../../../helpers/numbers"
import { RootState } from "../../../slices"
import { Investment } from "../models/investment"
import { AttributeItem, AttributeType } from "../../../core/components/AttributeItem"

interface IProps {
  investment?: Investment
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const InvestmentSummary = ({
  investment,
  readOnly = true,
  values,
  handleChange,
  errors,
  touched,
}: IProps) => {
  const { types } = useSelector((state: RootState) => state.investmentTypes)

  return (
    <div className='summaryContainer'>
      <div className='highlights'>
        <AttributeItem
          header='Round Size'
          value={investment?.roundSize ?? 0.0}
          type={AttributeType.Currency}
          inputType='text'
          className='highlightsColumn column1'
          readOnly={readOnly}
          item='roundSize'
          values={values.roundSize}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Target Investment'
          value={investment?.targetInvestment ?? 0.0}
          type={AttributeType.Currency}
          inputType='text'
          className='highlightsColumn column2'
          readOnly={readOnly}
          item = 'targetInvestment'
          values = {values.targetInvestment}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Currently Allocated'
          value={investment?.currentlyAllocated ?? 0.0}
          type={AttributeType.Currency}
          className='highlightsColumn column3'
          readOnly={true}
        />
        <AttributeItem
          header='State'
          value={
            types?.investmentStates.find(
              (state: Type) => state.id === investment?.state
            )?.name ?? " - "
          }
          type={AttributeType.Number || AttributeType.Null}
          inputType='select'
          className='highlightsColumn column4'
          readOnly={readOnly}
          item = 'state'
          values = {values.state}
          arrayOfValues = {
            types?.investmentStates.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
      </div>
    </div>
  )
}
