import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TableHeader } from "../../../core/components/TableHeader"
import { TablePagination } from "../../../core/components/TablePagination"
import { Sort } from "../../../core/models/sort"
import { TableColumn } from "../../../core/models/tables"
import { RootState } from "../../../slices"
import { CompanyInvestment } from "../models/portfolio"
import { fetchPortfolio, setPage, setSort } from "../slices/portfolio"
import { PortfolioListItem } from "./PortfolioListItem"

interface IProps {
  investor: number
}

const portfolioTableColumns: TableColumn[] = [
  { value: "" },
  { value: "Company", field: "company__name" },
  { value: "Total Invested", field: "total_investment" },
  { value: "Current Value" },
  { value: "Growth" },
]

export const PortfolioList = ({ investor }: IProps) => {
  const dispatch = useDispatch()
  const { portfolio, next, previous, count, page, sort } = useSelector(
    (state: RootState) => state.portfolio
  )

  useEffect(() => {
    dispatch(
      fetchPortfolio({
        investor: investor,
        page,
        sort,
      })
    )
  }, [dispatch, investor, page, sort])

  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  return (
    <div className={"portfolio-table"}>
      {!portfolio.length && <p className="warningMessageWrapper">No investments yet.</p>}

      {portfolio.length !== 0 && (
        <>
        <div className={"grid-table"}>
          <div className='grid-table-row'>
            {portfolioTableColumns.map((column: TableColumn, index: number) => (
              <TableHeader
                key={`portfolio-table-header-${index}`}
                value={column.value}
                field={column.field}
                sort={sort}
                onSort={handleSort}
              />
            ))}
          </div>

          {portfolio.map((companyInvestment: CompanyInvestment) => {
            return (
              <PortfolioListItem
                key={`table-row-${companyInvestment.id}`}
                companyInvestment={companyInvestment}
              />
            )
          })}
        </div>
        <TablePagination
          next={next}
          onNext={handleNext}
          onPrevious={handlePrevious}
          previous={previous}
          page={page}
          count={count ?? 0}
        />
        </>
      )}
    </div>
  )
}
