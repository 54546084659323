import { combineReducers } from "@reduxjs/toolkit"

import allocationsReducer from "./features/allocations/slices/allocations"
import applicationsReducer from "./features/applications/slices/applications"
import applicationTypesReducer from "./features/applications/slices/applicationTypes"
import authReducer from "./features/user/slices/auth"
import investmentReducer from "./features/investments/slices/investment"
import investmentsReducer from "./features/investments/slices/investments"
import investmentTypesReducer from "./features/investments/slices/investmentTypes"
import fundsReducer from "./features/funds/slices/funds"
import companiesReducer from "./features/companies/slices/companies"
import companyReducer from "./features/companies/slices/company"
import companyTypesReducer from "./features/companies/slices/companyTypes"
import companySectorsReducer from "./features/companies/slices/companySectors"
import investorReducer from "./features/investors/slices/investor"
import investorsReducer from "./features/investors/slices/investors"
import investorTypesReducer from "./features/investors/slices/investorTypes"
import investorInterestsReducer from "./features/investors/slices/investorInterests"
import portfolioReducer from "./features/portfolio/slices/portfolio"
import subscriptionsReducer from "./features/subscriptions/slices/subscriptions"
import subscriptionTypesReducer from "./features/subscriptions/slices/subscriptionTypes"

const rootReducer = combineReducers({
  allocations: allocationsReducer,
  applications: applicationsReducer,
  applicationTypes: applicationTypesReducer,
  auth: authReducer,
  companies: companiesReducer,
  company: companyReducer,
  companyTypes: companyTypesReducer,
  companySectors: companySectorsReducer,
  funds: fundsReducer,
  investment: investmentReducer,
  investments: investmentsReducer,
  investmentTypes: investmentTypesReducer,
  investor: investorReducer,
  investors: investorsReducer,
  investorTypes: investorTypesReducer,
  investorInterests: investorInterestsReducer,
  portfolio: portfolioReducer,
  subscriptions: subscriptionsReducer,
  subscriptionTypes: subscriptionTypesReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
