import {
  AttributeItem,
  AttributeType,
} from "./AttributeItem"

interface IProps {
  title: string
  item?: string
  value?: string
  type?: AttributeType
  subtitles: Object
  className?: string
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const SummaryItemWithSubtitles = ({
  title,
  subtitles,
  value = '',
  type,
  className, 
  readOnly = true,
  item = '',
  values, 
  handleChange, 
  errors, 
  touched }: IProps) => {

  return (
    <div className={`highlightsColumn ${className ?? ""}`}>
      <div className='header'>{title}</div>
      <div className='subtitlesWrapper'>
        {
          Object.entries(subtitles).map(([key, value]: [string, string], index: number) => 
            <div key={`subtitle-${index}`} className={`subtitlesColumn column${index+1}`}>
              <AttributeItem
                header={key}
                value={value}
                type={type ||  AttributeType.Number || AttributeType.Null}
                readOnly={readOnly}
                item = {item}
                values = {values}
                handleChange = {handleChange}
                errors = {errors}
                touched = {touched}
              />
            </div>
          )
        }
      </div>
    </div>
  )
}
