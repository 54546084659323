import { MenuItem, TextField } from "@material-ui/core"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import debounce from "lodash.debounce"
import { Type } from "../../../core/models/type"
import { RootState } from "../../../slices"
import { setFilters, setLoading } from "../slices/allocations"

import NumberFormat from 'react-number-format'

const AllocationFilters = () => {
  const dispatch = useDispatch()
  const { types } = useSelector((state: RootState) => state.investmentTypes)
  const [localFilters, setLocalFilters] = useState(
    useSelector((state: RootState) => state.allocations.filters) ?? {}
  )

  const renderOptions = (types?: Type[]) => [
    <MenuItem key={0} value={0}>
      Any
    </MenuItem>,
    ...(types ?? []).map((type: Type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    )),
  ]

  const handleChange = (field: any, e: string | React.ChangeEvent<HTMLInputElement> ) => {
    let value: any = (typeof e === 'string') ? e : e.target.value

    if (value === "") value = null
    if (value === 0) value = null

    const updatedFilters = {
      ...(localFilters ?? {}),
      [field]: value,
    }

    setLocalFilters(updatedFilters)
    dispatch(setLoading())

    debounce(() => dispatch(setFilters(updatedFilters)), 500, {
      maxWait: 2500,
    })()
  }

  return (
    <div className='filters allocationspageBodyTopWrapper'>
      <form>
        <div className='filtersInnerWrapper allocationFilters'>
          <div className='filtersColumn allocationsFiltersColumn column1'>
            <div className='header'>Search</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Investor'
                variant='outlined'
                size='small'
                value={localFilters.search ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("search", e)
                }
              />
            </div>
          </div>
          <div className='filtersColumn allocationsFiltersColumn column2'>
            <div className='header'>Tax Relief</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Any'
                variant='outlined'
                size='small'
                value={localFilters.tax_relief ?? 0}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("tax_relief", e)
                }
              >
                {renderOptions(types?.taxRelief)}
              </TextField>
            </div>
          </div>
          <div className='filtersColumn allocationsFiltersColumn column3'>
            <div className='header'>Investment Amount</div>
            <div className='inputWrapper inputNumberFormatted'>
              <NumberFormat
                placeholder='From'
                value={localFilters.investment_amount_from ?? ""} 
                thousandSeparator={true} 
                prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("investment_amount_from", values.value)
                }}
              />
              <div className='inputInnerWrapperSplitter'>&nbsp;</div>
              <NumberFormat
                placeholder='To'
                value={localFilters.investment_amount_to ?? ""} 
                thousandSeparator={true} 
                prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("investment_amount_to", values.value)
                }}
              />
            </div>
          </div>
          <div className='filtersColumn allocationsFiltersColumn column3'>
            <div className='header'>Shares</div>
            <div className='inputWrapper inputNumberFormatted'>
              <NumberFormat
                placeholder='From'
                value={localFilters.shares_from ?? ""} 
                thousandSeparator={true} 
                // prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("shares_from", values.value)
                }}
              />
              <div className='inputInnerWrapperSplitter'>&nbsp;</div>
              <NumberFormat
                placeholder='To'
                value={localFilters.shares_to ?? ""} 
                thousandSeparator={true} 
                // prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("shares_to", values.value)
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AllocationFilters
