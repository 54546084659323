import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Type } from "../../../core/models/type"
import { baseHeaders, processResponse } from "../../../helpers/requests"

interface InvestorInterestState {
  investorInterests?: Type[]
  loading: boolean
  error?: string
}

const initialState: InvestorInterestState = {
  loading: true,
}

// Async
export const fetchInvestorInterests = createAsyncThunk(
  "investment/fetchInvestorInterests",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}core/sectors/`,
        baseHeaders()
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investorInterestsSlice = createSlice({
  name: "investorInterests",
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestorInterests.fulfilled, (state, { payload }) => {
      state.investorInterests = payload.results
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading } = investorInterestsSlice.actions

export default investorInterestsSlice.reducer
