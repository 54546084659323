import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { fetchCompanies, setSort } from "../slices/companies"
import { CompanyListItem } from "./CompanyListItem"
import { Company } from "../models/company"
import { Sort } from "../../../core/models/sort"
import { TableColumn } from "../../../core/models/tables"
import { TableHeader } from "../../../core/components/TableHeader"

interface IProps {
  companies: any
  sort: Sort
}

interface Props {
  company: Company
}

const companyTableColumns: TableColumn[] = [
  { value: "" },
  { value: "Company", field: "name" },
  { value: "Valuation" },
  { value: "Amount Invested" },
  { value: "GMOIC" },
  { value: "IRR" },
  { value: "Investment Date" },
  { value: "" },
]

export const CompanyTable = ({ companies, sort }: IProps) => {
  const dispatch = useDispatch()

  const { 
    loadingTable
  } = useSelector((state: RootState) => state.companies)

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  if (loadingTable) return <Loader />

  return (
    <div className='tableCompanyList'>
      <div className='grid-table'>
        <div className='grid-table-row'>
          {companyTableColumns.map((column: TableColumn, index: number) => (
            <TableHeader
              key={`investment-table-header-${index}`}
              value={column.value}
              field={column.field}
              sort={sort}
              onSort={handleSort}
            />
          ))}
        </div>

        {companies.map((company: Company) => (
          <CompanyListItem key={company.id} company={company} />
        ))}
      </div>
    </div>
  )
}
