import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router"
import { RootState } from "../../../slices"
import { setInitialState } from "../slices/auth"
import { LoginForm } from "../components/LoginForm"
import { Loader } from "../../../core/components/Loader"

const LoginPage = () => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch(setInitialState())
  }

  const { 
    user,
    loading,
    error
  } = useSelector((state: RootState) => state.auth)

  if (user) return <Redirect to={"/investors"} />

  if (loading) return <Loader />

  if (error)
    return (
      <div className='errorMessageWrapper'>
        <p>Login failed: {error}</p>
        <br />
        <button onClick={handleReset}>Try again</button>
      </div>
    )

  return (
    <div className='loginWrap'>
      <LoginForm />
    </div>
  )
}

export default LoginPage
