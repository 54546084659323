import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { fetchAllSubscriptions } from "../../subscriptions/slices/subscriptions"
import { Type } from "../../../core/models/type"
import { Investment } from "../models/investment"
import { Subscription } from "../../subscriptions/models/subscription"
import { TableSwitch } from "../../../core/components/TableSwitch"
import { fetchAllocations } from "../../allocations/slices/allocations"
import { AttributeItem, AttributeType } from "../../../core/components/AttributeItem"
import CalculatedTotalField from "../../../core/components/CalculatedTotalField"
import ClosestNumber from "../../../core/components/CalculatedClosestNumberDivisibleBy"
import { TextButton } from "../../../core/components/TextButton"

import { createAllocation, resetAllocationError } from "../../../features/allocations/slices/allocations"

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { formatCurrency } from '../../../helpers/numbers'

import { Formik } from "formik"
import * as Yup from "yup"

interface IProps {
  investment: Investment
}

interface ISubscription {
  totalInvestable: number | undefined | null
  subscriptionDate: Date | undefined | null
  type: string | undefined | null
}

interface ISubmitValues {
  investment: string | number
  investmentAmount: string | number
  shares: string | number
  subscription: string | number
  taxRelief: string | number
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const AddNewAllocation = ({ investment }: IProps) => {
  const dispatch = useDispatch()

  const [switchAmountShares, setSwitchAmountShares] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [subscription, setSubscription] = useState<ISubscription>({
    totalInvestable: null,
    subscriptionDate: null,
    type: ''
  })

  const {
    allSubscriptions,
    loading,
    sort,
    error
  } = useSelector((state: RootState) => state.subscriptions)

  const { 
    types 
  } = useSelector((state: RootState) => state.investmentTypes)

  const handleClickOpenModal = () => {
    dispatch(fetchAllSubscriptions({
      sort: {
        field: 'total_investable',
        direction: 'DESC'
      }}))
    setOpenModal(true)
  }

  const handleClickCloseModal= () => {
    setOpenModal(false)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleSwitchAmountShares = (switcher: boolean) => {
    setSwitchAmountShares(switcher)
  }

  const onHandleChange = (subs: Subscription) => {
    if (subs) {
      let subscription = allSubscriptions.find(e => e.id === subs.id)
      setSubscription({
        totalInvestable: subscription?.totalInvestable,
        subscriptionDate: subscription?.subscriptionDate,
        type: subscription?.type === 1 ? 'Standard' : 'Co-investment'
      })
    } else {
      setSubscription({
        totalInvestable: null,
        subscriptionDate: null,
        type: null
      })
    }
  }
  
  const handleFormSubmit = (values: any) => {
    dispatch(createAllocation({ values }))
    handleClickCloseModal()
    dispatch(
      fetchAllocations({
        investment: investment?.id,
      })
    )
  }

  useEffect(() => {
    if (!loading) {
      if (error === 'Rejected') handleClickAlert()
    }
    dispatch(resetAllocationError())
  }, [dispatch, sort, loading, error])

  return (
    <div className='actionsModalWindow'>
      <div className='addNewButton'>
        <a onClick={handleClickOpenModal}>
          Add New Allocation
        </a>
      </div>

      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          There was a network error. Please try again later.
        </Alert>
      </Snackbar>

      <Dialog open={openModal} onClose={handleClickCloseModal} aria-labelledby="form-dialog-title" className='actionsBottomMenuAllocations' maxWidth="lg">

        <DialogTitle id="form-dialog-title">New Allocation</DialogTitle>

        {
          loading 
          ? <Loader classStyle="modalWindow"/>
          : <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues = {{
                investment: investment.id,
                switch: switchAmountShares,
                subscription: '',
                taxRelief: 1,
                investmentAmount: '', 
                noShares: '',
                totalPrice: ''
              }}
              onSubmit = {val => {
                let values: ISubmitValues = {
                  investment: val.investment,
                  investmentAmount: val.switch
                    ? +Number(val.totalPrice).toFixed(2)
                    : +Number(val.investmentAmount).toFixed(2),
                  shares: val.switch
                    ? val.noShares
                    : +Number(val.totalPrice).toFixed(2),
                  subscription: val.subscription,
                  taxRelief: val.taxRelief
                }
                return handleFormSubmit({ values })
              }}
              validationSchema = {Yup.object().shape({
                subscription: Yup
                  .number()
                  .typeError("This is a required field")
                  .required('This is a required field'),
                taxRelief: Yup
                  .number()
                  .typeError("Must be a number"),
                investmentAmount: Yup.number()
                  .when('switch', {
                    is: (val) => val === false,
                    then: Yup.number()
                            .required('This is a required field')
                            .test({
                              name: 'validator-custom-name',
                              // eslint-disable-next-line object-shorthand
                              test: function(value) {
                                let res = ClosestNumber(value, investment.sharePrice)
                                return res
                                  ? this.createError({
                                      message: `The investment amount is not divisible by the share price. Closest options are ${res}`,
                                      path: 'investmentAmount',
                                    })
                                  : true
                              },
                            }),
                  }),
                noShares: Yup.number()
                  .when('switch', {
                    is: (val) => val === true,
                    then: Yup.number()
                            .required('This is a required field')
                            .positive('Please provide positive number')
                            .integer('Please provide integer'),
                  }),
                totalPrice: Yup.number()
                  .when('switch', {
                    is: (val) => val === true,
                    then: Yup.number()
                      .required('This is a required field')
                      .test({
                        name: 'validator-custom-name',
                        // eslint-disable-next-line object-shorthand
                        test: function(value) {
                          let res = ClosestNumber(value, investment.sharePrice)
                          return res
                            ? this.createError({
                                message: `The investment amount is not divisible by the share price. Closest options are ${res}`,
                                path: 'totalPrice',
                              })
                            : true
                        },
                      }),
                  }),
              })}
            >
              { props => {
                const {
                  values,
                  touched,
                  errors,
                  // isSubmitting,
                  handleChange,
                  handleSubmit,
                  setFieldValue
                } = props
                return (
                  <form >
                    <DialogContent>
                    <div className='formContainer modalWindowContianer attributesContainer'>
                      <div className='columnsWrap'>
                        <div className='columnLeft'>
                          <div className={`attributeContainer`}>
                            <span className='header'>Subscriptions</span>
                            <span className='value'>
                              <div className={`inputInnerWrapper`}>
                                <Autocomplete
                                  freeSolo
                                  id="subscription"
                                  options={allSubscriptions.reduce(function (arrObjs: any, item: any) {
                                    if (Number(item.totalInvestable) > 0) arrObjs.push({ 
                                      name: item.name + ' - ' + formatCurrency(item.totalInvestable, true), id: item.id 
                                    })
                                    return arrObjs
                                  }, [])}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event: any, newValue: any) => {
                                    onHandleChange(newValue)
                                    setFieldValue('subscription', newValue ? newValue.id : null)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      margin="normal"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                <div className='input-feedback'>{errors.subscription}</div>
                                <div className='investorArea'>
                                  <p><b>Total Investable:</b> £{ subscription.totalInvestable }</p>
                                  <p><b>Subscription date:</b> { subscription.subscriptionDate }</p>
                                  <p><b>Type:</b> { subscription.type }</p>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div className='columnRight'>
                          <AttributeItem
                            header='Tax Relief'
                            value={''}
                            type={AttributeType.Text}
                            inputType='select'
                            readOnly={false}
                            item = 'taxRelief'
                            values = {values.taxRelief}
                            arrayOfValues = {
                              types?.taxRelief.reduce<Record<string, number>>(function (obj, item: Type) {
                                obj[item.name] = item.id
                                return obj
                              }, {})
                            }
                            handleChange = {handleChange}
                            errors = {errors}
                            touched = {touched}
                          />

                          <div className="newAllocation">
                            <TableSwitch
                              labels={['£', 'Shares']}
                              active={switchAmountShares}
                              onChanged={() => { 
                                setFieldValue('switch', !switchAmountShares)
                                if (!switchAmountShares) {
                                  setFieldValue('noShares', values.totalPrice)
                                  setFieldValue('totalPrice', values.investmentAmount)
                                }  
                                if (switchAmountShares) {
                                  setFieldValue('investmentAmount', values.totalPrice)
                                  setFieldValue('totalPrice', values.noShares)
                                } 
                                handleSwitchAmountShares(!switchAmountShares)}
                              }
                            />
                            <div className={`table${!switchAmountShares ? "Active" : "Hidden"}`}>
                              <label htmlFor="investmentAmount" style={{ display: "block" }}>
                                Investment Amount
                              </label>
                              <div className="investmentAmountWrap">
                                <span className="pound">£</span>
                                <input
                                  id="investmentAmount"
                                  name="investmentAmount"
                                  placeholder=""
                                  type="number"
                                  value={values.investmentAmount}
                                  onChange={handleChange}
                                  className={
                                    errors.investmentAmount && touched.investmentAmount
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {errors.investmentAmount && touched.investmentAmount && (
                                  <div className="input-feedback">{errors.investmentAmount}</div>
                                )}
                              </div>
                            </div>

                            <div className={`table${switchAmountShares ? "Active" : "Hidden"}`}>
                              <label htmlFor="noShares" style={{ display: "block" }}>
                                  No. of shares
                              </label>
                              <div className="noSharesWrap">
                                <input
                                  id="noShares"
                                  name="noShares"
                                  placeholder=""
                                  type="number"
                                  value={values.noShares}
                                  onChange={handleChange}
                                  className={
                                    errors.noShares && touched.noShares
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {errors.noShares && touched.noShares && (
                                  <div className="input-feedback">{errors.noShares}</div>
                                )}
                              </div>
                            </div>

                            <label htmlFor="totalPrice" className="totalPrice">
                                { switchAmountShares ? "Total Price" : "Total Shares" }
                              </label>
                            <div className={`totalPriceWrap ${switchAmountShares ? "pounds" : "shares"}`}>
                              <span className={`pound table${switchAmountShares ? "Active" : "Hidden"}`}>£</span>
                              <CalculatedTotalField
                                switchAmountShares={switchAmountShares}
                                sharePrice={investment.sharePrice}
                                id="totalPrice"
                                type="text"
                                name="totalPrice"
                                value={values.totalPrice}
                                values={values}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                className={
                                  errors.totalPrice && touched.totalPrice
                                    ? "text-input error"
                                    : "text-input"
                                }
                              />
                              {switchAmountShares && errors.totalPrice && touched.totalPrice && (
                                <div className="input-feedback">{errors.totalPrice}</div>
                              )}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <TextButton onClick={handleClickCloseModal}>
                        <span>Cancel</span>
                      </TextButton>
                      <TextButton onClick={handleSubmit}>
                        <button type="submit">Submit</button>
                      </TextButton>
                    </DialogActions>
                  </form>
                )
              }}
            </Formik>
      }
      </Dialog>
    </div>
  )
}
