import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { CompanyTypes } from "../models/company"
import { baseHeaders, processResponse } from "../../../helpers/requests"

interface CompanyTypeState {
  types?: CompanyTypes
  loading: boolean
  error?: string
}

const initialState: CompanyTypeState = {
  loading: true,
}

// Async
export const fetchCompanyTypes = createAsyncThunk(
  "company/fetchCompanyTypes",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}companies/types/`,
        baseHeaders()
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const companyTypesSlice = createSlice({
  name: "companyTypes",
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchCompanyTypes.fulfilled, (state, { payload }) => {
      state.types = payload
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading } = companyTypesSlice.actions

export default companyTypesSlice.reducer
