import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TableHeader } from "../../../core/components/TableHeader"
import { TablePagination } from "../../../core/components/TablePagination"
import { Sort } from "../../../core/models/sort"
import { TableColumn } from "../../../core/models/tables"
import { RootState } from "../../../slices"
import { Subscription } from "../models/subscription"
import { fetchSubscriptions, setPage, setSort } from "../slices/subscriptions"
import { SubscriptionListItem } from "./SubscriptionListItem"

interface IProps {
  investor: number
}

const subscriptionTableColumns: TableColumn[] = [
  { value: "Investment Amount", field: "investment_amount" },
  { value: "Type", field: "type" },
  { value: "Fund", field: "fund" },
  { value: "Subscription Date", field: "subscription_date" },
]

export const SubscriptionList = ({ investor }: IProps) => {
  const dispatch = useDispatch()
  const { subscriptions, next, previous, count, page, sort } = useSelector(
    (state: RootState) => state.subscriptions
  )

  useEffect(() => {
    dispatch(
      fetchSubscriptions({
        investor: investor,
        page,
        sort,
      })
    )
  }, [dispatch, investor, page, sort])

  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  return (
    <div className={"subscription-table"}>
      {!subscriptions.length && <p className="warningMessageWrapper">No subscriptions yet.</p>}

      {subscriptions.length !== 0 && (
        <>
        <div className={"grid-table"}>
          <div className='grid-table-row'>
            {subscriptionTableColumns.map(
              (column: TableColumn, index: number) => (
                <TableHeader
                  key={`subscription-table-header-${index}`}
                  value={column.value}
                  field={column.field}
                  sort={sort}
                  onSort={handleSort}
                />
              )
            )}
          </div>

          {subscriptions.map((subscription: Subscription) => {
            return (
              <SubscriptionListItem
                key={`table-row-${subscription.id}`}
                subscription={subscription}
              />
            )
          })}
        </div>
        <TablePagination
          next={next}
          onNext={handleNext}
          onPrevious={handlePrevious}
          previous={previous}
          page={page}
          count={count ?? 0}
        />
        </>
      )}
    </div>
  )
}
