import React, { useEffect } from "react";

const ClosestNumber = (n: number, sharePrice: number): undefined | string => {
 
    if (Number.isInteger(n / sharePrice))
    return

    // find the quotient
    let q = Math.floor(n / sharePrice)
     
    // 1st possible closest number
    let n1 = sharePrice * q
     
    // 2nd possible closest numbers
    let n2 = (n * sharePrice) > 0 ?
        (sharePrice * (q + 1)) : (sharePrice * (q - 1))

    return n1 + ' (low) or ' + n2 + ' (high)'
}

export default ClosestNumber