import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import localStore from "./middleware/localstore"

import rootReducer, { RootState } from "./slices"

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: localStore.loadState(),
})

store.subscribe(() => {
  const state = store.getState()
  localStore.saveState(state)
})

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
