import React from "react"
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { RootState } from "../../../slices"
import { fetchCompanies, setPage, setInitialState } from "../slices/companies"
import { CompanyFilters } from "../components/CompanyFilters"
import { CompanyHighlights } from "../components/CompanyHighlights"
import { TableSwitch } from "../../../core/components/TableSwitch"
import { TablePagination } from "../../../core/components/TablePagination"
import { ActionsTopMenu } from "../components/ActionsTopMenu"
import { fetchCompanySectors } from "../slices/companySectors"

import { CompanyTable } from "../components/CompanyTable"
import { CompanyChart } from "../components/CompanyTableChart"

import { Loader } from "../../../core/components/Loader"

const CompaniesListPage = () => {
  const dispatch = useDispatch()
  const {
    results: companies,
    loading,
    error,
    next,
    previous,
    count,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.companies)
  const [tableMode, setTableMode] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const componentWillUnmount = useRef(false)

  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleReset = () => {
    dispatch(setInitialState())
  }

  useEffect(() => {
    componentWillUnmount.current = true
    dispatch(fetchCompanySectors())
    dispatch(fetchCompanies({ page, sort, filters }))
  }, [dispatch, page, sort, filters])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) dispatch(setInitialState())
    }
  }, [dispatch])

  if (loading) return <Loader />

  return (
    <div className='pageWrapper companyListPage'>
      <div className='pageBodyTopWrapper'>
        <ActionsTopMenu
          newModelName='Company'
        />

        <div className='filtersDesktopWrapper'>
          <CompanyFilters />
        </div>
        <div className='filtersMobileWrapper'>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CompanyFilters />
            </AccordionDetails>
          </Accordion>
        </div>

        <CompanyHighlights />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
          <TableSwitch
            active={tableMode}
            onChanged={() => setTableMode(!tableMode)}
          />

          {error && <div className='warningMessageWrapper'>
            <p>Request failed: {error}</p>
            <br />
            <button onClick={handleReset}>Try again</button>
          </div>}

          {!error && !companies.length && <p className="warningMessageWrapper">No investors found.</p>}

          {companies.length !== 0 && !error && (
            <>
              <div className={`table${!tableMode ? "Active" : "Hidden"}`}>
                <CompanyTable companies={companies} sort={sort} />
              </div>

              <div className={`table${tableMode ? "Active" : "Hidden"}`}>
                <CompanyChart 
                  companies={companies} 
                  maxValue={ Math.max(...companies.map(o => o.currentValuation), 0) }
                />
              </div>

              <TablePagination
                next={next}
                onNext={handleNext}
                onPrevious={handlePrevious}
                previous={previous}
                page={page}
                count={count ?? 0}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CompaniesListPage
