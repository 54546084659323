import { SummaryItem } from "../../../core/components/SummaryItem"
import { SummaryItemWithSubtitles } from "../../../core/components/SummaryItemWithSubtitles"
import { formatNumber, formatCurrency } from "../../../helpers/numbers"
import { Investor } from "../models/investor"

import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"

interface Props {
  investor?: Investor
  readOnly?: boolean
}

export const InvestorSummary = ({ investor, readOnly = true }: Props) => {
  return (
    <div className='summaryContainer'>
      <div className='highlights'>
        <SummaryItem
          title='Total Subscription'
          type={AttributeType.Currency}
          value={investor?.totalSubscribed ?? 0.0}
          className='column1'
          readOnly={true}
        />
        <SummaryItem
          title='Total Invested'
          type={AttributeType.Currency}
          value={investor?.totalInvested ?? 0.0}
          className='column2'
          readOnly={true}
        />
        <SummaryItem
          title='Total Investable'
          type={AttributeType.Currency}
          value={investor?.totalInvestable ?? 0.0}
          className='column3'
          readOnly={true}
        />
        <SummaryItemWithSubtitles
          title='Total Fees Paid'
          type={AttributeType.Currency}
          subtitles={{
            'Excl VAT': investor?.totalFeesPaidExlcVat ?? 0.0,
            'Incl VAT': investor?.totalFeesPaidInclVat ?? 0.0
          }}
          className='column4'
          readOnly={true}
        />
        <SummaryItem
          title='TPV'
          type={AttributeType.Currency}
          value={investor?.tvpi ?? 0.0}
          className='column5'
          readOnly={true}
        />
        <SummaryItem
          title='IRR'
          type={AttributeType.Currency}
          value={investor?.irr ?? 0.0+'%'}
          className='column6'
          readOnly={true}
        />
      </div>
    </div>
  )
}
