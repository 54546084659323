import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Investment } from "../models/investment"
import { baseHeaders, processResponse } from "../../../helpers/requests"

import { keysToSnake } from "../../../helpers/toSnake"

interface InvestmentState {
  investment?: Investment
  loading: boolean
  error?: string
}
interface IdProp {
  id?: string
  values?: any
}

const initialState: InvestmentState = {
  loading: true,
  error: undefined
}

// Async
export const fetchInvestment = createAsyncThunk(
  "investment/fetchInvestment",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}investments/${id}/`,
        baseHeaders()
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const updateInvestment = createAsyncThunk(
  "investment/updateInvestment",
  async ({ id, values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_HOST}investments/${id}/`,
        {
          ...keysToSnake(params)
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const createInvestment = createAsyncThunk(
  "investment/createInvestment",
  async ({ values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}investments/`,
        {
          ...keysToSnake(params.values)
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const deleteInvestment = createAsyncThunk(
  "investment/deleteInvestment",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .delete(`${process.env.REACT_APP_API_HOST}investments/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investmentSlice = createSlice({
  name: "investment",
  initialState,
  reducers: {
    setInitialState: state => {
      state.loading = false
    },
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    resetInvestmentError: state => {
      state.error = undefined
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestment.fulfilled, (state, { payload }) => {
      state.investment = payload
      state.loading = false
    })
    addCase(fetchInvestment.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(updateInvestment.fulfilled, (state, { payload }) => {
      state.investment = payload
      state.loading = false
    })
    addCase(updateInvestment.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(createInvestment.fulfilled, (state, { payload }) => {
      state.investment = payload
      state.loading = false
    })
    addCase(createInvestment.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(deleteInvestment.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    addCase(deleteInvestment.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setInitialState, setLoading, resetInvestmentError } = investmentSlice.actions

export default investmentSlice.reducer