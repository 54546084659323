import { useSelector } from "react-redux"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { RootState } from "../../../slices"
import { Company } from "../models/company"

interface Props {
  company?: Company
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const CompanyDescription = ({
  company,
  readOnly = true,
  values, 
  handleChange, 
  errors, 
  touched }: Props) => {

  return (
    <div className='companyDescriptionContainer'>
      <div className='left'>{company?.logo && <img alt="" src={company?.logo} />}</div>
      <div className='right'>
        <h1 className='companyName'>
          <AttributeItem
            header=''
            value={company?.name}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'name'
            values = {values.name}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
        </h1>
      </div>
    </div>
  )
}
