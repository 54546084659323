import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { fetchAllCompanies } from "../../companies/slices/companies"
import { Type } from "../../../core/models/type"

import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { TextButton } from "../../../core/components/TextButton"

import {
  createInvestment,
  resetInvestmentError,
} from "../../../features/investments/slices/investment"

import { fetchInvestments } from "../slices/investments"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"

import { Formik } from "formik"
import * as Yup from "yup"

interface IProps {
  newModelName: string
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export const ActionsTopMenu = ({ newModelName }: IProps) => {
  const dispatch = useDispatch()

  const {
    allCompanies: companies,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.companies)

  const {
    loading,
    // investment,
    error,
  } = useSelector((state: RootState) => state.investment)

  const { types } = useSelector((state: RootState) => state.investmentTypes)

  const [openModal, setOpenModal] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)

  const handleClickOpenModal = () => {
    setOpenModal(true)
  }
  const handleClickCloseModal = () => {
    setOpenModal(false)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }
  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlert(false)
  }

  const handleFormSubmit = (values: any) => {
    dispatch(createInvestment({ values }))
    dispatch(fetchInvestments({ page, sort }))
  }

  useEffect(() => {
    if (!loading) {
      // if (error === 'Rejected') handleClickAlert()
      handleClickCloseModal()
    }
    dispatch(
      fetchAllCompanies({
        sort: {
          field: "name",
          direction: "ASC",
        },
      })
    )
    dispatch(resetInvestmentError())
  }, [loading, error])

  return (
    <div className='actionsTopMenu'>
      <div className='addNewButton'>
        <a onClick={handleClickOpenModal}>+ Add New {newModelName}</a>
      </div>

      {/*
      <div className='exportCSV'>
        <a>Export CSV</a>
      </div>
      */}

      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity='error'>
          There was a network error. Please try again later.
        </Alert>
      </Snackbar>

      <Dialog
        open={openModal}
        onClose={handleClickCloseModal}
        aria-labelledby='form-dialog-title'
        className='actionsTopMenuInvestments'
        maxWidth='lg'
      >
        <DialogTitle id='form-dialog-title'>New Investment</DialogTitle>

        {loading ? (
          <Loader classStyle='modalWindow' />
        ) : (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              company: null,
              roundSize: "",
              targetInvestment: "",
              state: 1,
              sharePrice: "",
              targetInvestmentDate: "",
              nominalPrice: "",
              // valuationPreMoney: '',
              shareType: 1,
              taxRelief: 1,
              // valuationPostMoney: ''
            }}
            onSubmit={values => handleFormSubmit({ values })}
            validationSchema={Yup.object().shape({
              company: Yup.number()
                .typeError("This is a required field")
                .required("This is a required field"),
              roundSize: Yup.number()
                .typeError("Must be a number")
                .required("This is a required field"),
              targetInvestment: Yup.number()
                .typeError("Must be a number")
                .required("This is a required field"),
              state: Yup.number()
                .typeError("Must be a number")
                .required("This is a required field"),
              sharePrice: Yup.number()
                .typeError("Must be a number")
                .required("This is a required field"),
              targetInvestmentDate: Yup.date()
                .typeError("Must be a date")
                .required("This is a required field"),
              nominalPrice: Yup.number()
                .typeError("Must be a number")
                .required("This is a required field"),
              // valuationPreMoney: Yup.number().typeError("Must be a number"),
              shareType: Yup.number().typeError("Must be a number"),
              taxRelief: Yup.number().typeError("Must be a number"),
              // valuationPostMoney: Yup.number().typeError("Must be a number")
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                // isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props
              return (
                <form>
                  <DialogContent>
                    <div className='formContainer attributesContainer'>
                      <div className='columnsWrap'>
                        <div className='columnLeft'>
                          <div className={`attributeContainer`}>
                            <span className='header'>Company</span>
                            <span className='value'>
                              <div className={`inputInnerWrapper`}>
                                <Autocomplete
                                  freeSolo
                                  id='company'
                                  options={companies
                                    .reduce(function (
                                      arrObjs: any,
                                      item: Type
                                    ) {
                                      arrObjs.push({
                                        name: item.name,
                                        id: item.id,
                                      })
                                      return arrObjs
                                    },
                                    [])
                                    .map((company: any) => company.name)}
                                  onChange={(event: any, newValue: any) => {
                                    setFieldValue(
                                      "company",
                                      companies.find(e => e.name === newValue)
                                        ?.id
                                    )
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setFieldValue(
                                      "company",
                                      companies.find(
                                        e => e.name === newInputValue
                                      )?.id
                                    )
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      margin='normal'
                                      variant='outlined'
                                    />
                                  )}
                                />
                                <div className='input-feedback'>
                                  {errors.company}
                                </div>
                              </div>
                            </span>
                          </div>
                          <AttributeItem
                            header='Round Size'
                            value={""}
                            type={AttributeType.Number || AttributeType.Null}
                            inputType='text'
                            className='highlightsColumn column1'
                            readOnly={false}
                            item='roundSize'
                            values={values.roundSize}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Target Investment'
                            value={""}
                            type={AttributeType.Number || AttributeType.Null}
                            inputType='text'
                            className='highlightsColumn column2'
                            readOnly={false}
                            item='targetInvestment'
                            values={values.targetInvestment}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='State'
                            value=''
                            type={AttributeType.Number || AttributeType.Null}
                            inputType='select'
                            className='highlightsColumn column4 hide'
                            readOnly={false}
                            item='state'
                            values={1}
                            arrayOfValues={types?.investmentStates.reduce<
                              Record<string, number>
                            >(function (obj, item: Type) {
                              obj[item.name] = item.id
                              return obj
                            }, {})}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Share Price'
                            value={values.sharePrice}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='sharePrice'
                            values={values.sharePrice}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='columnRight'>
                          <AttributeItem
                            header='Target Investment'
                            value={values.targetInvestmentDate}
                            type={AttributeType.Date}
                            inputType='date'
                            readOnly={false}
                            item='targetInvestmentDate'
                            values={values.targetInvestmentDate}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Nominal Value'
                            value={values.nominalPrice}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='nominalPrice'
                            values={values.nominalPrice}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Share Type'
                            value={""}
                            type={AttributeType.Number || AttributeType.Null}
                            inputType='select'
                            readOnly={false}
                            item='shareType'
                            values={values.shareType}
                            arrayOfValues={types?.shareTypes.reduce<
                              Record<string, number>
                            >(function (obj, item: Type) {
                              obj[item.name] = item.id
                              return obj
                            }, {})}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Tax Relief'
                            value={""}
                            type={AttributeType.Text}
                            inputType='select'
                            readOnly={false}
                            item='taxRelief'
                            values={values.taxRelief}
                            arrayOfValues={types?.taxRelief.reduce<
                              Record<string, number>
                            >(function (obj, item: Type) {
                              obj[item.name] = item.id
                              return obj
                            }, {})}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          {/*
                          <AttributeItem
                            header='Valuation (pre-money)'
                            value={''}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='valuationPreMoney'
                            values={values.valuationPreMoney}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <AttributeItem
                            header='Valuation (post-money)'
                            value={''}
                            type={AttributeType.Text}
                            inputType='text'
                            readOnly={false}
                            item='valuationPostMoney'
                            values={values.valuationPostMoney}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          */}
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <TextButton onClick={handleClickCloseModal}>
                      <span>Cancel</span>
                    </TextButton>
                    <TextButton onClick={handleSubmit}>
                      <button type='submit'>Submit</button>
                    </TextButton>
                  </DialogActions>
                </form>
              )
            }}
          </Formik>
        )}
      </Dialog>
    </div>
  )
}
