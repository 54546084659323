import { useSelector } from "react-redux"
import { formatCurrency } from "../../../helpers/numbers"
import { RootState } from "../../../slices"
import { Subscription } from "../models/subscription"
import { getTypeById } from "../../../helpers/types"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  subscription: Subscription
}

export const SubscriptionListItem = ({ subscription }: Props) => {
  const { types } = useSelector((state: RootState) => state.subscriptionTypes)

  return (
    <div className='grid-table-row list-item allocation-list-item'>
      <div className='grid-table-cell' data-title='Amount Investment'>
        <TooltipCurrency amount={subscription.investmentAmount ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Type'>
        {getTypeById(types?.subscriptionTypes, subscription.type)?.name ??
          " - "}
      </div>
      <div className='grid-table-cell' data-title='Fund'>
        {subscription.fund.name}
      </div>
      <div className='grid-table-cell' data-title='Subscription Date'>
        {subscription.subscriptionDate}
      </div>
    </div>
  )
}
