import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"

import { RootState } from "../../../slices"
import { InvestmentAttributes } from "./InvestmentAttributes"
import { InvestmentCompany } from "./InvestmentCompany"
import { InvestmentSummary } from "./InvestmentSummary"

import { updateInvestment, deleteInvestment } from "../slices/investment"

import { Formik } from "formik"
import * as Yup from "yup"

import { TextButton } from "../../../core/components/TextButton"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { fetchInvestments } from "../slices/investments"

interface ParamType {
  id: string
}

const InvestmentDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const investment = useSelector(
    (state: RootState) => state.investment.investment
  )
  const [readOnly, setReadOnly] = useState(true)
  const [open, setOpen] = useState(false)
  
  const {
    results: investments,
    loading,
    error,
    next,
    previous,
    count,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.investments)

  const handleClickOpen = () => {
    setReadOnly(true)
    setOpen(true)
  }

  const handleClose = () => {
    setReadOnly(false)
    setOpen(false)
  }

  const onDeleteInvestment = () => {
    history.push("/investments")
    dispatch(fetchInvestments({ page, sort }))
    dispatch(deleteInvestment({ id: id }))
  }

  const getTextButtonChild = () => {
    return readOnly ? (
      <span>Edit Investment</span>
    ) : (
      <div className="actionsButtonsWrap">
        <button type="submit">
          Save Investor
        </button>
        <button className={`buttonDelete ${readOnly ? 'hidden': ''}`} onClick={e => {
          e.preventDefault()
          handleClickOpen()
        }}>
          Delete Investment
        </button>
      </div>
    )
  }

  return (
    <>
    <Formik
      initialValues = {{
        roundSize: investment?.roundSize,
        targetInvestment: investment?.targetInvestment,
        state: investment?.state,
        sharePrice: investment?.sharePrice,
        targetInvestmentDate: investment?.targetInvestmentDate,
        nominalPrice: investment?.nominalPrice,
        valuationPreMoney: investment?.valuationPreMoney,
        shareType: investment?.shareType,
        sharesOffered: investment?.sharesOffered,
        taxRelief: investment?.taxRelief,
        valuationPostMoney: investment?.valuationPostMoney
      }}
      onSubmit = {values => {
        if (!readOnly) {
          dispatch(updateInvestment({ id, values }))
        }
        setReadOnly(!readOnly)
      }}
      validationSchema = {Yup.object().shape({
        // roundSize: Yup.number().typeError("Must be a number"),
        // targetInvestment: Yup.number().typeError("Must be a number"),
        // state: Yup.number().typeError("Must be a number"),
        // sharePrice: Yup.number().typeError("Must be a number"),
        // targetInvestmentDate: Yup.date().typeError("Must be a date"),
        // nominalPrice: Yup.number().typeError("Must be a number"),
        // valuationPreMoney: Yup.number().typeError("Must be a number"),
        // shareType: Yup.number().typeError("Must be a number"),
        // sharesOffered: Yup.number().typeError("Must be a number"),
        // taxRelief: Yup.number().typeError("Must be a number"),
        // valuationPostMoney: Yup.number().typeError("Must be a number")
      })}
    >
      { props => {
        const {
          values,
          touched,
          errors,
          // isSubmitting,
          handleChange,
          handleSubmit
        } = props
        return (
          <form>
            <div className='header'>
              <InvestmentCompany company={investment?.company}/>
              <InvestmentSummary 
                investment={investment}  
                readOnly={readOnly}
                values = {values}
                handleChange = {handleChange}
                errors = {errors}
                touched = {touched}
              />
            </div>
            <InvestmentAttributes
              investment={investment}
              readOnly={readOnly}
              values = {values}
              handleChange = {handleChange}
              errors = {errors}
              touched = {touched}
            />
            <TextButton onClick={handleSubmit}>
              {getTextButtonChild()}
            </TextButton>
          </form>
        )
      }}
    </Formik>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this investment?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This investment will be deleted inmediately.<br/>You can't undo this action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={handleClose}>
          <b>Cancel</b>
        </TextButton>
        <button 
          className={`buttonDelete small`}
          onClick={onDeleteInvestment}
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default InvestmentDetails
