import { Investment } from "../models/investment"
import { AddNewAllocation } from "./AddNewAllocation"
import { BulkActions } from "./BulkActions"

interface IProps {
  investment: Investment
}

export const ActionsBottomMenu = ({ investment }: IProps) => {
  return (
    <div className='actionsModalWindow actionsBottomMenu'>

      <AddNewAllocation
        investment={investment}
      />
      <span className="divider"> | </span> 
      <BulkActions
        investment={investment}
      />

    </div>
  )
}
