import { InvestmentListItemChart } from "./InvestmentListItemChart"
import { Investment } from "../models/investment"
import { xAxisTable } from "../../../helpers/xAxisTableChart"
import { formatCurrency } from "../../../helpers/numbers"

interface IProps {
  investments: any
  maxValue: number
}

export const InvestmentTableChart = ({ investments, maxValue }: IProps) => {
  return (
    <div className='tableInvestorListChart'>
      <div className='grid-table'>
        <div className='grid-table-row'>
          <div className='grid-table-cell'>Investment</div>
          <div className='grid-table-cell'>
            { xAxisTable(maxValue).map((x: number) => (
              <span className='yaxis' key={x}>
                { formatCurrency(x, true, true, true) }
              </span>
            )) }
          </div>
        </div>

        {investments.map((investment: Investment) => (
          <InvestmentListItemChart 
            key={investment.id} 
            investment={investment}  
            maxValueWeight={String(Math.trunc( maxValue )).length}/>
        ))}
      </div>
    </div>
  )
}
