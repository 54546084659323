import loader from "../../assets/images/loader.svg"

interface IProps {
  classStyle?: string
}

export const Loader = ({ classStyle }: IProps) => {
  return (
    <div className={`loaderWrapper ${classStyle}`}>
      <p>
        <img src={loader} alt={"loader"} />
      </p>
    </div>
  )
}
