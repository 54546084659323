import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"

import { RootState } from "../../../slices"
import { CompanyAttributes } from "./CompanyAttributes"
import { CompanyDescription } from "./CompanyDescription"
import { CompanySummary } from "./CompanySummary"

import { updateCompany, deleteCompany } from "../slices/company"

import { Formik } from "formik"
import * as Yup from "yup"

import { TextButton } from "../../../core/components/TextButton"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface ParamType {
  id: string
}

const CompanyDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const company = useSelector(
    (state: RootState) => state.company.company
  )
  const [readOnly, setReadOnly] = useState(true)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setReadOnly(true)
    setOpen(true)
  }

  const handleClose = () => {
    setReadOnly(false)
    setOpen(false)
  }

  const onDeleteCompany = () => {
    history.push("/companies")
    dispatch(deleteCompany({ id: id }))
  }

  const getTextButtonChild = () => {
    return readOnly ? (
      <span>Edit Company</span>
    ) : (
      <div className="actionsButtonsWrap">
        <button type="submit">
          Save Company
        </button>
        <button className={`buttonDelete ${readOnly ? 'hidden': ''}`} onClick={e => {
          e.preventDefault()
          handleClickOpen()
        }}>
          Delete Company
        </button>
      </div>
    )
  }

  return (
    <>
    <Formik
      initialValues = {{
        // id: company?.id,
        name: company?.name,
        // logo: company?.logo,
        amountInvested: company?.amountInvested,
        gmoic: company?.gmoic,
        irr: company?.irr,
        firstInvestmentDate: company?.firstInvestmentDate,
        currentValuation: company?.currentValuation,
        sectors: company?.sectors,
        domicile: company?.domicile,
        registrationNumber: company?.registrationNumber
      }}
      onSubmit = {values => {
        if (!readOnly) {
          dispatch(updateCompany({ id, values }))
        }
        setReadOnly(!readOnly)
      }}
      validationSchema = {Yup.object().shape({
        // id: Yup.number().typeError("Must be a number"),
        name: Yup.string(),
        // logo: Yup.string(),
        amountInvested: Yup.number(),
        gmoic: Yup.number().typeError("Must be a number"),
        irr: Yup.number().typeError("Must be a number"),
        // firstInvestmentDate: Yup.date().typeError("Must be a date"),
        currentValuation: Yup.number(),
        sectors: Yup.array(),
        domicile: Yup.string(),
        registrationNumber: Yup.number().typeError("Must be a number")
      })}
    >
      { props => {
        const {
          values,
          touched,
          errors,
          // isSubmitting,
          handleChange,
          handleSubmit
        } = props
        return (
          <form>
            <div className='header'>
              <CompanyDescription
                company={company}
                readOnly={readOnly}
                values = {values}
                handleChange = {handleChange}
                errors = {errors}
                touched = {touched}
              />
              <CompanySummary 
                company={company}
                readOnly={readOnly}
                values = {values}
                handleChange = {handleChange}
                errors = {errors}
                touched = {touched}
              />
            </div>
            <CompanyAttributes
              company={company}
              readOnly={readOnly}
              values = {values}
              handleChange = {handleChange}
              errors = {errors}
              touched = {touched}
            />
            <TextButton onClick={handleSubmit}>
              {getTextButtonChild()}
            </TextButton>
          </form>
        )
      }}
    </Formik>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this company?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This company will be deleted inmediately.<br/>You can't undo this action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={handleClose}>
          <b>Cancel</b>
        </TextButton>
        <button 
          className={`buttonDelete small`}
          onClick={onDeleteCompany}
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default CompanyDetails
