import React, { useEffect } from "react"

const CalculatedTotalField = (props: any) => {
  useEffect(() => {
    let val = 0

    if (!props.switchAmountShares) val = props.values.investmentAmount / Number(props.sharePrice)
    if (props.switchAmountShares) val = props.values.noShares * Number(props.sharePrice)

    props.setFieldValue("totalPrice", val)
  }, [props.values])

  return (
    <input
      id="totalPrice"
      type="number"
      name="totalPrice"
      value={Number(props.values.totalPrice).toFixed(2)} // .toLocaleString("en-GB")
      readOnly
    />
  )

}

export default CalculatedTotalField