import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Investment, InvestmentFilters } from "../models/investment"
import {
  baseHeaders,
  parseOrdering,
  processResponse,
} from "../../../helpers/requests"
import { Sort } from "../../../core/models/sort"
import { RootState } from "../../../slices"

interface InvestmentsState {
  results: Investment[]
  count?: number
  next?: string
  previous?: string
  page: number
  sort: Sort
  filters: InvestmentFilters
  totalInvested?: number
  totalInvestable?: number
  loading: boolean
  loadingTable: boolean
  error?: string
}

const initialState: InvestmentsState = {
  results: [],
  loading: true,
  loadingTable: false,
  page: 1,
  filters: {
    search: null,
    state: null,
    tax_relief: null,
    fund: null,
  },
  sort: {
    field: "target_investment_date",
    direction: "DESC",
  },
  error: undefined
}

interface FetchInvestmentsProp {
  page?: number
  sort?: Sort
  filters?: InvestmentFilters
}

// Async
export const fetchInvestments = createAsyncThunk(
  "investments/fetchInvestments",
  async (
    { page, sort, filters = initialState.filters }: FetchInvestmentsProp,
    { rejectWithValue, dispatch, getState }
  ) => {

    !(getState() as RootState).investments.results.length && !Object.values(filters).some(x => x !== null && x !== '')
      ? dispatch(setLoading())
      : dispatch(setLoadingTable())

    sort = sort || (getState() as RootState).investments.sort

    let params: any = {}
    params.page = page ?? 1
    params.ordering = parseOrdering(sort)
    params = { ...params, ...filters }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}investments/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res), page: page ?? 1, sort: sort }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investmentsSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setInitialState: state => {
      state.results = []
      state.page = 1
      state.error = undefined
      state.filters = {
        search: null,
        state: null,
        tax_relief: null,
        fund: null,
      }
      state.sort = {
        field: "target_investment_date",
        direction: "DESC",
      }
      state.loading = false
    },
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    setLoadingTable: state => {
      state.error = undefined
      state.loadingTable = true
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestments.fulfilled, (state, { payload }) => {
      state.results = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.page = payload.page
      state.sort = payload.sort
      state.totalInvested = payload.totalInvested
      state.totalInvestable = payload.totalInvestable
      state.loading = false
      state.loadingTable = false
      state.error = undefined
    })
    addCase(fetchInvestments.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
      state.loadingTable = false
    })
  },
})

// Actions generated from the slice
export const {
  setInitialState,
  setLoading,
  setLoadingTable,
  setPage,
  setSort,
  setFilters,
} = investmentsSlice.actions

export default investmentsSlice.reducer
