import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchLogin } from "../slices/auth"

import { Formik } from "formik"
import * as Yup from "yup"
import { FormInput } from "../../../core/components/FormInputs"

export const LoginForm = () => {
  const dispatch = useDispatch()

  const handleLogin = (email: string, password: string) => {
    dispatch(
      fetchLogin({
        email: email,
        password: password,
      })
    )
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={values => handleLogin(values.email, values.password)}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Required"),
        password: Yup.string().required("Required"),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <div className='loginFormWrapper'>
              <div className='loginFormColumn'>
                <FormInput
                  htmlFor='email'
                  label='Email'
                  id='email'
                  placeholder='Enter your email'
                  type='text'
                  values={values.email}
                  handleChange={handleChange}
                  errors={errors.email}
                  touched={touched.email}
                />

                <FormInput
                  htmlFor='password'
                  label='Password'
                  id='password'
                  placeholder='Enter your password'
                  type='password'
                  values={values.password}
                  handleChange={handleChange}
                  errors={errors.password}
                  touched={touched.password}
                />
              </div>
            </div>
            <div className='loginFormButtons'>
              <button type='submit' disabled={isSubmitting}>
                Login
              </button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}
