import { useSelector } from "react-redux"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { Type } from "../../../core/models/type"
import { getTypeById } from "../../../helpers/types"
import { RootState } from "../../../slices"
import { Company } from "../models/company"

interface IProps {
  company?: Company
  item?: string
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const CompanyAttributes = ({
  company,
  readOnly = true,
  item = "",
  values,
  handleChange,
  errors,
  touched,
}: IProps) => {
  const { types } = useSelector((state: RootState) => state.companyTypes)

  const { 
    companySectors
  } = useSelector((state: RootState) => state.companySectors)

  return (
    <div className='attributesContainer'>
      <div className='attributeGrid'>
        <AttributeItem
          header='Registration Number'
          value={company?.registrationNumber}
          type={AttributeType.Text}
          inputType='text'
          readOnly={true}
          item='registrationNumber'
          values={values.registrationNumber}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Incorporation date '
          value={company?.firstInvestmentDate}
          type={AttributeType.Date}
          inputType='date'
          readOnly={readOnly}
          item='firstInvestmentDate'
          values={values.firstInvestmentDate}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Location'
          value={company?.domicile}
          type={AttributeType.Text}
          inputType='text'
          readOnly={readOnly}
          item='domicile'
          values={values.domicile}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Sectors'
          // value={
          //   company?.sectors.map( (el: Type) => companySectors?.find((x: Type) => x.id === Number(el))?.name + ' | ')
          // }
          value={
            company?.sectors.map( (el: Type) =>  el.name + ' | ')
          }
          type={AttributeType.Array}
          inputType='multipleSelect'
          readOnly={readOnly}
          item = 'sectors'
          // values = {values.sectors}
          values = {values.sectors?.map( (el: Type) => (typeof el === 'number') ? el : el.id)}
          arrayOfValues = {
            companySectors?.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='GMOIC'
          value={company?.gmoic}
          type={AttributeType.Number}
          inputType='text'
          readOnly={readOnly}
          item='gmoic'
          values={values.gmoic}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='IRR'
          value={company?.irr}
          type={AttributeType.Number}
          inputType='text'
          readOnly={readOnly}
          item='irr'
          values={values.irr}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
    </div>
  )
}
