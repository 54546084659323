import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { TableHeader } from "../../../core/components/TableHeader"
import { TablePagination } from "../../../core/components/TablePagination"
import { Sort } from "../../../core/models/sort"
import { TableColumn } from "../../../core/models/tables"
import { getTypeById } from "../../../helpers/types"
import { RootState } from "../../../slices"
import { Investment } from "../../investments/models/investment"
import { Investor } from "../../investors/models/investor"
import { Allocation } from "../models/allocation"
import { fetchAllocations, setPage, setSort, deleteAllocation } from "../slices/allocations"
import { fetchInvestment } from "../../investments/slices/investment"
import AllocationFilters from "./AllocationFilters"
import { AllocationListItem } from "./AllocationListItem"

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core"

import { TextButton } from "../../../core/components/TextButton"

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export enum TableMode {
  Investment,
  Investor,
}

interface IProps {
  mode?: TableMode
  investment?: Investment
  investor?: Investor
}

const investmentAllocationTableColumns: TableColumn[] = [
  { value: "Subscription", field: "subscription__name" },
  {
    value: "Target Deployment Date",
    field: "subscription__target_deployment_date",
  },
  { value: "Total Investable", field: "subscription__total_investable" },
  { value: "Tax Relief", field: "tax_relief__order" },
  { value: "Investment Amount", field: "investment_amount" },
  { value: "No. of Shares", field: "shares" },
  { value: "", field: "id" },
]

const investedAllocationTableColumns: TableColumn[] = [
  { value: "Subscription", field: "subscription__name" },
  { value: "Investment Amount", field: "investment_amount" },
  { value: "No. of Shares", field: "shares" },
  { value: "Tax Relief", field: "tax_relief__order" },
]

const investorAllocationTableColumns: TableColumn[] = [
  { value: "" },
  { value: "Company", field: "investment__company__name" },
  { value: "Amount Invested", field: "investment_amount" },
  { value: "Current Value" },
  { value: "Growth" },
  { value: "Investment Date", field: "investment__target_investment_date" },
  { value: "State", field: "investment__state__order" },
]

interface ParamType {
  id: string
}

export const AllocationList = ({mode = TableMode.Investment, investment, investor,}: IProps) => {
  const dispatch = useDispatch()
  const { id } = useParams<ParamType>()
  const {
    allocations,
    next,
    previous,
    count,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.allocations)
  const { types } = useSelector((state: RootState) => state.investmentTypes)

  const [expanded, setExpanded] = useState<string | false>(false)
  const [open, setOpen] = useState(false)
  const [deleteAllocationId, setDeleteAllocationId] = useState<number>()

  const isInvested =
  getTypeById(types?.investmentStates, investment?.state)?.name === "Invested"

  const handleClickOpen = (id: number) => {
    setDeleteAllocationId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onDeleteAllocation = () => {
    handleClose()
    dispatch(deleteAllocation({ id: String(deleteAllocationId) }))
    dispatch(fetchInvestment({ id: String(investment?.id) }))
  }
  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleSort = (sort: Sort) => {
    dispatch(setSort({ page: 1, sort: sort }))
  }

  const handleExpandToggle = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }
   
  useEffect(() => {
    if (investment?.id === Number(id) || investor?.id === Number(id)) {
      dispatch(
        fetchAllocations({
          investment: investment?.id,
          // investment: Number(id),
          investor: investor?.id,
          page,
          sort,
          filters,
        })
      )
    }
  }, [dispatch, investment, investor, page, sort, filters])

  return (
    <>
      {mode === TableMode.Investment && (
        <div className='filtersDesktopWrapper'>
          <AllocationFilters />
        </div>
      )}

      {mode === TableMode.Investment && (
        <div className='filtersMobileWrapper'>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleExpandToggle("panel1")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AllocationFilters />
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div
        className={`allocation-table${
          mode === TableMode.Investor ? " investor-mode" : ""
        }${isInvested ? " invested-mode" : ""}`}
      >
        {!allocations.length && <p className="warningMessageWrapper">No allocations yet.</p>}

        {allocations.length !== 0 && (
          <>
            <div className={"grid-table"}>
              <div className='grid-table-row'>
                {(mode === TableMode.Investor
                  ? investorAllocationTableColumns
                  : isInvested
                    ? investedAllocationTableColumns
                    : investmentAllocationTableColumns
                ).map((column: TableColumn, index: number) => (
                  <TableHeader
                    key={`allocation-table-header-${index}`}
                    value={column.value}
                    field={column.field}
                    sort={sort}
                    onSort={handleSort}
                  />
                ))}
              </div>
              
              {allocations.map((allocation: Allocation) => {
                return (
                  <AllocationListItem
                    key={`table-row-${allocation?.subscription?.id}`}
                    mode={mode}
                    allocation={allocation}
                    isInvested={isInvested}
                    handleClickOpen={handleClickOpen}
                  />
                )
              })}
            </div>
            <TablePagination
              next={next}
              onNext={handleNext}
              onPrevious={handlePrevious}
              previous={previous}
              page={page}
              count={count ?? 0}
            />
          </>
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this allocation?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This allocation will be deleted inmediately.<br/>You can't undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TextButton onClick={handleClose}>
            <b>Cancel</b>
          </TextButton>
          <button 
            className={`buttonDelete small`}
            onClick={onDeleteAllocation}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
