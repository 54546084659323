import React from "react"
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { RootState } from "../../../slices"
import { fetchInvestments, setPage, setInitialState } from "../slices/investments"
import InvestmentFilters from "../components/InvestmentFilters"
import { InvestmentHighlights } from "../components/InvestmentHighlights"
import { ActionsTopMenu } from "../components/ActionsTopMenu"
import { TableSwitch } from "../../../core/components/TableSwitch"
import { TablePagination } from "../../../core/components/TablePagination"
import { fetchFunds } from "../../funds/slices/funds"
import { fetchInvestmentTypes } from "../slices/investmentTypes"

import { InvestmentTable } from "../components/InvestmentTable"
import { InvestmentTableChart } from "../components/InvestmentTableChart"

import { Loader } from "../../../core/components/Loader"

const InvestmentsListPage = () => {
  const dispatch = useDispatch()
  const {
    results: investments,
    loading,
    error,
    next,
    previous,
    count,
    page,
    sort,
    filters,
  } = useSelector((state: RootState) => state.investments)
  const [tableMode, setTableMode] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const componentWillUnmount = useRef(false)

  const handleNext = (val: number) => {
    dispatch(setPage(val))
  }

  const handlePrevious = (val: number) => {
    dispatch(setPage(val))
  }

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleReset = () => {
    dispatch(setInitialState())
  }

  useEffect(() => {
    dispatch(fetchInvestmentTypes())
    dispatch(fetchFunds({}))
  }, [dispatch])

  useEffect(() => {
    componentWillUnmount.current = true
    dispatch(fetchInvestments({ page, sort, filters }))
  }, [dispatch, page, sort, filters])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) dispatch(setInitialState())
    }
  }, [dispatch])

  if (loading) return <Loader />

  return (
    <div className='pageWrapper investmentsListPage'>
      <div className='pageBodyTopWrapper'>
        <ActionsTopMenu
          newModelName='Investments'
        />
        <div className='filtersDesktopWrapper'>
          <InvestmentFilters />
        </div>

        <div className='filtersMobileWrapper'>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestmentFilters />
            </AccordionDetails>
          </Accordion>
        </div>

        <InvestmentHighlights />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
          <TableSwitch
            active={tableMode}
            onChanged={() => setTableMode(!tableMode)}
          />

          {error && <div className='warningMessageWrapper'>
            <p>Request failed: {error}</p>
            <br />
            <button onClick={handleReset}>Try again</button>
          </div>}

          {!error && !investments.length && <p className="warningMessageWrapper">No investors found.</p>}

          {investments.length !== 0 && !error && (
            <>
              <div className={`table${!tableMode ? "Active" : "Hidden"}`}>
                <InvestmentTable investments={investments} sort={sort} />
              </div>

              <div className={`table${tableMode ? "Active" : "Hidden"}`}>
                <InvestmentTableChart 
                  investments={investments} 
                  maxValue={ Math.max(...investments.map(o => o.targetInvestment), 0) }/>
              </div>

              <TablePagination
                next={next}
                onNext={handleNext}
                onPrevious={handlePrevious}
                previous={previous}
                page={page}
                count={count ?? 0}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvestmentsListPage
