import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosRequestConfig } from "axios"
import { Sort } from "../../../core/models/sort"
import {
  baseHeaders,
  parseOrdering,
  processResponse,
} from "../../../helpers/requests"
import { RootState } from "../../../slices"
import { Subscription } from "../models/subscription"

interface SubscriptionsState {
  allSubscriptions: Subscription[]
  subscriptions: Subscription[]
  count?: number
  next?: string
  previous?: string
  loading: boolean
  error?: any
  page: number
  sort: Sort
}

const initialState: SubscriptionsState = {
  allSubscriptions: [],
  subscriptions: [],
  loading: true,
  page: 1,
  sort: { field: "subscription_date", direction: "DESC" },
  error: undefined
}

interface FetchSubscriptionsProp {
  investor: number
  page?: number
  sort?: Sort
}

interface FetchAllSubscriptionsProp {
  sort?: Sort
}

// Async
export const fetchSubscriptions = createAsyncThunk(
  "portfolio/fetchSubscriptions",
  async (
    { investor, page, sort }: FetchSubscriptionsProp,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setLoading())

    sort = sort || (getState() as RootState).subscriptions.sort

    let params: any = {}
    params.investor = investor
    params.page = page ?? 1
    params.ordering = parseOrdering(sort)

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}subscriptions/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res), page: page ?? 1 }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
// Async
export const fetchAllSubscriptions = createAsyncThunk(
  "portfolio/fetchAllSubscriptions",
  async (
    { sort }: FetchAllSubscriptionsProp,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setLoading())

    sort = sort || (getState() as RootState).subscriptions.sort

    let params: any = {
      page: 1,
      // investment_amount_from: 0,
      total_investable_from: 1,
      page_size: 10000
    }
    params.ordering = parseOrdering(sort)

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}subscriptions/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res) }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const subscriptionsSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    resetSubscriptionError: state => {
      state.error = undefined
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page ?? 1
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchSubscriptions.fulfilled, (state, { payload }) => {
      state.subscriptions = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.page = payload.page
      state.loading = false
      state.error = undefined
    })
    addCase(fetchSubscriptions.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(fetchAllSubscriptions.fulfilled, (state, { payload }) => {
      state.allSubscriptions = payload.results.filter( (subs: Subscription) => subs.totalInvestable > 0)
      state.loading = false
      state.error = undefined
    })
    addCase(fetchAllSubscriptions.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading, setPage, setSort, resetSubscriptionError } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
