import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Sort } from "../../../core/models/sort"
import { baseHeaders, processResponse } from "../../../helpers/requests"
import { RootState } from "../../../slices"
import { Allocation, AllocationFilters } from "../models/allocation"

import { keysToSnake } from "../../../helpers/toSnake"

interface AllocationsState {
  allocations: Allocation[]
  count?: number
  next?: string
  previous?: string
  loading: boolean
  error?: string
  page: number
  sort: Sort
  filters: AllocationFilters
}

const initialState: AllocationsState = {
  allocations: [],
  loading: true,
  page: 1,
  sort: { field: "subscription__name", direction: "ASC" },
  filters: {
    search: null,
    tax_relief: null,
    investment_amount_from: null,
    investment_amount_to: null,
    shares_from: null,
    shares_to: null,
    target_deployment_date_from: null,
    target_deployment_date_to: null,
  },
  error: undefined
}

interface FetchAllocationsProp {
  investment?: number
  investor?: number
  page?: number
  sort?: Sort
  filters?: AllocationFilters
}

interface IdProp {
  id?: string
  values?: any
}

interface ISubmitValues {
  investment: string | number
  investmentAmount: string | number
  shares: string | number
  subscription: string | number
  taxRelief: string | number
}

// Async
export const fetchAllocations = createAsyncThunk(
  "allocations/fetchAllocations",
  async (
    { investment, investor, page, sort, filters }: FetchAllocationsProp,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setLoading())

    sort = sort || (getState() as RootState).investments.sort
    
    let params: any = {}
    if (investment && !!investment) params.investment = investment
    if (investor && !!investor) params.subscription__investor = investor
    params.page = page ?? 1
    params.ordering = `${sort.direction === "DESC" ? "-" : ""}${sort.field}`

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}allocations/`,
        {
          ...baseHeaders(),
          params: { ...params, ...filters },
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res), page: page ?? 1 }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const createAllocation = createAsyncThunk(
  "investment/createAllocation",
  async ({ values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}allocations/`,
        {
          ...keysToSnake(params.values)
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const createBulkAllocation = createAsyncThunk(
  "investment/createBulkAllocation",
  async (values: Array<ISubmitValues>, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())
    
    let params: Array<ISubmitValues> = values

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}allocations/`,
        keysToSnake(params),
        {
          ...baseHeaders()
        }
      )
        
      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const deleteAllocation = createAsyncThunk(
  "investment/deleteAllocation",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .delete(`${process.env.REACT_APP_API_HOST}allocations/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const allocationsSlice = createSlice({
  name: "allocations",
  initialState,
  reducers: {
    setInitialState: state => {
      state.allocations = []
      state.page = 1
      state.error = undefined
      state.filters = {
        search: null,
        tax_relief: null,
        investment_amount_from: null,
        investment_amount_to: null,
        shares_from: null,
        shares_to: null,
        target_deployment_date_from: null,
        target_deployment_date_to: null,
      }
      state.sort = {
        field: "subscription__name",
        direction: "ASC"
      }
      state.loading = false
    },
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page ?? 1
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
      state.page = 1
    },
    resetAllocationError: state => {
      state.error = undefined
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchAllocations.fulfilled, (state, { payload }) => {
      state.allocations = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.page = payload.page
      state.loading = false
      state.error = undefined
    })
    addCase(fetchAllocations.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(createAllocation.fulfilled, (state, { payload }) => {
      state.allocations = payload
      state.loading = false
    })
    addCase(createAllocation.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(createBulkAllocation.fulfilled, (state, { payload }) => {
      state.allocations = payload
      state.loading = false
    })
    addCase(createBulkAllocation.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(deleteAllocation.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    addCase(deleteAllocation.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const {
  setInitialState,
  setLoading,
  setPage,
  setSort,
  setFilters,
  resetAllocationError
} = allocationsSlice.actions

export default allocationsSlice.reducer
