import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { format } from "url"
import { Type } from "../../../core/models/type"
import { getSectors } from "../../../helpers/companies"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { getTypeById } from "../../../helpers/types"
import { RootState } from "../../../slices"
import { Investment } from "../models/investment"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  investment: Investment
}

export const InvestmentListItem = ({ investment }: Props) => {
  const { types } = useSelector((state: RootState) => state.investmentTypes)

  return (
    <div className='grid-table-row list-item investment-list-item'>
      <div className='grid-table-cell' data-title='Company Logo'>
        {investment.company?.logo && (
          <img className='company-logo' src={investment.company.logo} />
        )}
      </div>
      <div className='grid-table-cell company' data-title='Company'>
        <Link to={`/investments/${investment.id}`}>
          <p>{investment.company.name}</p>
          <p>{investment.company && getSectors(investment.company)}</p>
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Amount Invested'>
        <TooltipCurrency amount={investment.currentlyAllocated ?? 0} />
      </div>
      <div className='grid-table-cell currentValue' data-title='Current Value'>
        <TooltipCurrency amount={investment.currentlyAllocated ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='State'>
        {getTypeById(types?.investmentStates, investment.state)?.name ?? " - "}
      </div>
      <div className='grid-table-cell' data-title='Tax Relief'>
        {getTypeById(types?.taxRelief, investment.taxRelief)?.name ?? " - "}
      </div>
      <div className='grid-table-cell' data-title='Investment Date'>
        {investment.targetInvestmentDate}
      </div>
      <div className='grid-table-cell' data-title=''>
        <Link to={`/investments/${investment.id}`}>View more</Link>
      </div>
    </div>
  )
}
