import React from "react"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

interface IProps {
  next?: string
  onNext: Function
  onPrevious: Function
  previous?: string
  page: number
  count: number
}

export const TablePagination = ({
  next,
  onNext,
  previous,
  onPrevious,
  page,
  count,
}: IProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    page > value ? onPrevious(value) : onNext(value)
  }

  return (
    <div className='tablePagination'>
      <Pagination
        count={Math.ceil(count / 10)}
        page={page}
        onChange={handleChange}
      />
    </div>
  )
}
