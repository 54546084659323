import React from "react"
import Tooltip from '@material-ui/core/Tooltip'
import { formatCurrency, formatNumber } from "../../helpers/numbers"

interface IProps {
  amount: number
}

const TooltipCurrency = (props: IProps) => {

  return (
    <Tooltip title={'£' + formatNumber(props.amount)}>
      <span className=''>
        {formatCurrency(props.amount, true)}
      </span>
    </Tooltip>
  )

}

export default TooltipCurrency