import Tooltip from '@material-ui/core/Tooltip'
import { formatCurrency, formatNumber } from "../../helpers/numbers"
import { FormInput } from "./FormInputs"

export enum AttributeType {
  Null,
  Text,
  Number,
  Currency,
  CurrencyNoRound,
  Date,
  Array,
  TextArea
}

interface IProps {
  header: string
  value: any
  className?: string
  type: AttributeType
  inputType?:
    | "text"
    | "password"
    | "number"
    | "quantity"
    | "textarea"
    | "checkbox"
    | "radio"
    | "select"
    | "multipleSelect"
    | "date"
  readOnly: boolean
  item?: string
  values?: any
  arrayOfValues?: object
  handleChange?: any
  errors?: any
  touched?: any
}

export const AttributeItem = ({ 
  header, 
  value,
  className,
  type,
  inputType = "text",
  readOnly,
  item = '',
  values, 
  arrayOfValues = {},
  handleChange,
  errors, 
  touched }: IProps) => {

  const renderValue = () => {
    if (readOnly) {
      if (value === undefined) return ""
      switch (type) {
        case AttributeType.Number:
          return formatNumber(value)
        case AttributeType.Currency:
          return formatCurrency(value, true)
        case AttributeType.CurrencyNoRound:
          return formatCurrency(value, false)
        case AttributeType.Date:
          return value
        default:
          return value
      }
    }
    return (
      <div className={`inputInnerWrapper ${className ?? ""}`}>
        <FormInput
          htmlFor={item}
          label={header}
          id={item}
          type = {inputType}
          values = {values}
          arrayOfValues = {arrayOfValues}
          handleChange = {handleChange}
          errors = {errors && errors[item]}
          touched = {touched && touched[item]}
        />
      </div>
    )
  }

  return (
    <div className={`attributeContainer ${className ?? ""}`}>
      <span className='header'>
        {header}
      </span>
      <Tooltip title={
        (type === AttributeType.Currency || type === AttributeType.CurrencyNoRound) 
          ? '£' + formatNumber(value)
          : ''
      }>
        <span className='value'>
          {renderValue()}
        </span>
      </Tooltip>
    </div>
  )
}
