import { useSelector } from "react-redux"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { Type } from "../../../core/models/type"
import { RootState } from "../../../slices"
import { Investor } from "../models/investor"

interface Props {
  investor?: Investor
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const InvestorAttributes = ({
  investor,
  readOnly = true,
  values, 
  handleChange, 
  errors, 
  touched }: Props) => {
  const { investorTypes } = useSelector((state: RootState) => state.investorTypes)

  const { 
    investorInterests
  } = useSelector((state: RootState) => state.investorInterests)

  return (
    <div className='attributesContainer'>
      <div className='attributeGrid'>
        <AttributeItem
          header='Phone'
          value={investor?.phone}
          type={AttributeType.Text}
          readOnly={readOnly}
          item = 'phone'
          values = {values.phone}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Email'
          value={investor?.email}
          type={AttributeType.Text}
          readOnly={readOnly}
          item = 'email'
          values = {values.email}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='COBS'
          value={investor?.cobs}
          type={AttributeType.Text}
          readOnly={readOnly}
          item = 'cobs'
          values = {values.cobs}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Interests'
          // value={
          //   investor?.interests.map( (el: Type) => investorInterests?.find((x: Type) => x.id === Number(el))?.name + ' | ')
          // }
          value={
            investor?.interests?.map( (el: Type) => el.name + ' | ')
          }
          type={AttributeType.Array}
          readOnly={readOnly}
          inputType='multipleSelect'
          item = 'interests'
          // values = {values.interests}
          values = {values.interests?.map( (el: Type) => (typeof el === 'number') ? el : el.id)}
          arrayOfValues = {
            investorInterests?.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Company'
          value={investor?.company}
          type={AttributeType.Text}
          readOnly={readOnly}
          item = 'company'
          values = {values.company}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Type'
          value={
            investorTypes?.find(
              (state: Type) => state.id === investor?.investorType
            )?.name
          }
          type={AttributeType.Number || AttributeType.Null}
          inputType='select'
          readOnly={readOnly}
          item = 'investorType'
          values = {values.investorType}
          arrayOfValues = {
            investorTypes?.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Social Media'
          value={investor?.linkedin}
          type={AttributeType.Text}
          readOnly={readOnly}
          item = 'linkedin'
          values = {values.linkedin}
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <div className="addressWrapper">
          <AttributeItem
            header='Address'
            value={investor?.addressStreet}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'addressStreet'
            values = {values.addressStreet}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
          <AttributeItem
            header=''
            value={investor?.addressPostcode}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'addressPostcode'
            values = {values.addressPostcode}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
          <AttributeItem
            header=''
            value={investor?.addressCity}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'addressCity'
            values = {values.addressCity}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
          <AttributeItem
            header=''
            value={investor?.addressCountry}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'addressCountry'
            values = {values.addressCountry}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
        </div>
      </div>

    </div>
  )
}
