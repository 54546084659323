import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Navbar } from "./core/components/Navbar"
import { ProtectedRoute } from "./core/components/ProtectedRoute"

import LoginPage from "./features/user/containers/LoginPage"
import InvestmentsListPage from "./features/investments/containers/InvestmentsListPage"
import InvestmentPage from "./features/investments/containers/InvestmentPage"
import CompaniesListPage from "./features/companies/containers/CompaniesListPage"
import CompanyPage from "./features/companies/containers/CompanyPage"
import InvestorsListPage from "./features/investors/containers/InvestorsListPage"
import InvestorPage from "./features/investors/containers/InvestorPage"
import { ApplicationDialog } from "./features/applications/components/ApplicationDialog"

const App = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/' component={LoginPage} />
        <ProtectedRoute
          exact
          path='/investments'
          component={InvestmentsListPage}
        />
        <ProtectedRoute
          exact
          path='/investments/:id'
          component={InvestmentPage}
        />
        <ProtectedRoute exact path='/companies' component={CompaniesListPage} />
        <ProtectedRoute exact path='/companies/:id' component={CompanyPage} />
        <ProtectedRoute exact path='/investors' component={InvestorsListPage} />
        <ProtectedRoute path='/investors/:id' component={InvestorPage} />
      </Switch>
      <ProtectedRoute
        path='/investors/:id/applications/:applicationId'
        component={ApplicationDialog}
      />
    </Router>
  )
}

export default App
