import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { User, Credentials } from "../models/user"
import { processResponse } from "../../../helpers/requests"

interface AuthState {
  loading: boolean
  token?: string
  user?: User
  error?: string
}

const initialState: AuthState = {
  loading: false,
}

// Asynchronous thunk action
export const fetchLogin = createAsyncThunk(
  "auth/fetchLogin",
  async (credentials: Credentials | null, thunk) => {
    if (credentials === null)
      return thunk.rejectWithValue("You must provider an email and password")

    thunk.dispatch(setLoading())

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}users/login/`,
        credentials
      )

      if (res.status === 404) {
        return thunk.rejectWithValue({
          message: "Email or password didn't match",
        })
      }

      return processResponse(res)
    } catch (error) {
      return thunk.rejectWithValue(error)
    }
  }
)

// A slice for auth
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setInitialState: _ => initialState,
    setLoading: state => {
      state.loading = true
    },
    getLoginSuccess: (state, { payload }) => {
      state.user = payload.user
      state.token = payload.token
      state.loading = false
      state.error = undefined
    },
    getLoginFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLogin.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(fetchLogin.fulfilled, (state, { payload }) => {
      state.user = payload.user
      state.token = payload.token
      state.loading = false
      state.error = undefined
    })
    builder.addCase(fetchLogin.rejected, (state, { error }) => {
      console.log(error)
      state.loading = false
      state.error = error.message
    })
  },
})

// Actions generated from the slice
export const { setInitialState, setLoading, getLoginSuccess, getLoginFailure } =
  authSlice.actions

// The reducer
export default authSlice.reducer
