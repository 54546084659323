import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Company } from "../models/company"
import { baseHeaders, processResponse } from "../../../helpers/requests"

import { keysToSnake } from "../../../helpers/toSnake"

interface CompanyState {
  company?: Company
  loading: boolean
  error?: string
}
interface IdProp {
  id?: string
  values?: any
}

const initialState: CompanyState = {
  loading: false,
  error: undefined
}

// Async
export const fetchCompany = createAsyncThunk(
  "company/fetchCompany",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .get(`${process.env.REACT_APP_API_HOST}companies/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const updateCompany = createAsyncThunk(
  "investment/updateCompany",
  async ({ id, values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_HOST}companies/${id}/`,
        {
          ...keysToSnake(params)
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const createCompany = createAsyncThunk(
  "investment/createCompany",
  async ({ values }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    let params: any = values

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}companies/`,
        {
          ...keysToSnake(params)
        },
        {
          ...baseHeaders()
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const deleteCompany = createAsyncThunk(
  "investment/deleteCompany",
  async ({ id }: IdProp, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      return await axios
        .delete(`${process.env.REACT_APP_API_HOST}companies/${id}/`, baseHeaders())
        .then(processResponse)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    resetCompanyError: state => {
      state.error = undefined
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchCompany.fulfilled, (state, { payload }) => {
      state.company = payload
      state.loading = false
    })
    addCase(fetchCompany.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.company = payload
      state.loading = false
    })
    addCase(updateCompany.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(createCompany.fulfilled, (state, { payload }) => {
      state.company = payload
      state.loading = false
    })
    addCase(createCompany.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
    addCase(deleteCompany.fulfilled, (state, { payload }) => {
      state.loading = false
    })
    addCase(deleteCompany.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading, resetCompanyError  } = companySlice.actions

export default companySlice.reducer
