import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosRequestConfig } from "axios"
import { Sort } from "../../../core/models/sort"
import {
  baseHeaders,
  parseOrdering,
  processResponse,
} from "../../../helpers/requests"
import { RootState } from "../../../slices"
import { Application } from "../models/application"

interface ApplicationsState {
  applications: Application[]
  count?: number
  next?: string
  previous?: string
  loading: boolean
  error?: string
  page: number
  sort: Sort
}

const initialState: ApplicationsState = {
  applications: [],
  loading: true,
  page: 1,
  sort: { field: "application_date", direction: "DESC" },
  error: undefined
}

interface FetchApplicationsProp {
  investor: number
  page?: number
  sort?: Sort
}

// Async
export const fetchApplications = createAsyncThunk(
  "portfolio/fetchApplications",
  async (
    { investor, page, sort }: FetchApplicationsProp,
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setLoading())

    sort = sort || (getState() as RootState).applications.sort

    let params: any = {}
    params.user = investor
    params.page = page ?? 1
    params.ordering = parseOrdering(sort)

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}applications/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res), page: page ?? 1 }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const applicationsSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page ?? 1
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchApplications.fulfilled, (state, { payload }) => {
      state.applications = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.page = payload.page
      state.loading = false
      state.error = undefined
    })
    addCase(fetchApplications.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading, setPage, setSort } = applicationsSlice.actions

export default applicationsSlice.reducer
