import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { getInvestorName } from "../../../helpers/strings"
import { RootState } from "../../../slices"
import { Allocation } from "../models/allocation"
import { TableMode } from "./AllocationList"
import { getSectors } from "../../../helpers/companies"
import { getTypeById } from "../../../helpers/types"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  allocation: Allocation
  mode: TableMode
  isInvested?: boolean
  handleClickOpen: Function
}

export const AllocationListItem = ({ allocation, mode, isInvested, handleClickOpen }: Props) => {
  const { types } = useSelector((state: RootState) => state.investmentTypes)

  if (mode === TableMode.Investor)
    return (
      <div className='grid-table-row list-item allocation-list-item'>
        <div className='grid-table-cell' data-title='Company Logo'>
          {allocation.investment?.company?.logo && (
            <img
              className='company-logo'
              src={allocation.investment?.company.logo}
            />
          )}
        </div>
        <div className='grid-table-cell company' data-title='Company'>
          <Link to={`/investments/${allocation.investment?.id}`}>
            <p>{allocation.investment?.company?.name ?? "-"}</p>
            <p>
              {allocation.investment?.company &&
                getSectors(allocation.investment.company)}
            </p>
          </Link>
        </div>
        <div className='grid-table-cell' data-title='Amount Invested'>
          <TooltipCurrency amount={allocation.investmentAmount ?? 0} />
        </div>
        <div
          className='grid-table-cell currentValue'
          data-title='Current Value'
        >
          <TooltipCurrency amount={allocation.currentValue ?? 0} />
        </div>
        <div className='grid-table-cell growth' data-title='Growth'>
          -
        </div>
        <div className='grid-table-cell' data-title='Investment Date'>
          {allocation.investment?.targetInvestmentDate}
        </div>
        <div className='grid-table-cell' data-title='State'>
          {getTypeById(types?.investmentStates, allocation.investment?.state)
            ?.name ?? " - "}
        </div>
      </div>
    )

  if (isInvested)
    return (
      <div className='grid-table-row list-item allocation-list-item'>
        <div className='grid-table-cell' data-title='Subscription'>
          <p className='allocation-investor'>{allocation.subscription?.name}</p>
          <p className='allocation-address'>
            SuperSeed, 231-232 Strand, London, WC2R 1DA
          </p>
        </div>
        <div className='grid-table-cell' data-title='Investment Amount'>
          <TooltipCurrency amount={allocation.investmentAmount ?? 0} />
        </div>
        <div className='grid-table-cell' data-title='No. of Shares'>
          <TooltipCurrency amount={allocation.shares ?? 0} />
        </div>
        <div className='grid-table-cell' data-title='Tax Relief'>
          {getTypeById(types?.taxRelief, allocation.taxRelief)?.name ?? " - "}
        </div>
      </div>
    )

  return (
    <div className='grid-table-row list-item allocation-list-item'>
      <div className='grid-table-cell' data-title='Subscription'>
        <p className='allocation-investor'>{allocation.subscription?.name}</p>
        <p className='allocation-address'>
          SuperSeed, 231-232 Strand, London, WC2R 1DA
        </p>
      </div>
      <div className='grid-table-cell' data-title='Target Deployment Date'>
        {allocation.subscription?.targetDeploymentDate ?? " - "}
      </div>
      <div
        className='grid-table-cell totalInvestable'
        data-title='Total Investable'
      >
        <TooltipCurrency amount={allocation.subscription?.totalInvestable ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='Tax Relief'>
        {getTypeById(types?.taxRelief, allocation.taxRelief)?.name ?? " - "}
      </div>
      <div className='grid-table-cell' data-title='Investment Amount'>
        <TooltipCurrency amount={allocation.investmentAmount ?? 0} />
      </div>
      <div className='grid-table-cell' data-title='No. of Shares'>
        {formatNumber(allocation.shares ?? 0.0)}
      </div>
      <div className='grid-table-cell' data-title='Id'>
        <button className='buttonDelete small' onClick={e => {
          e.preventDefault() 
          handleClickOpen(allocation.id)
          // onDeleteAllocation({allocation.id})
        }}>
          Delete
        </button>
      </div>
    </div>
  )
}
