import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"

import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import {
  AllocationList,
  TableMode,
} from "../../allocations/components/AllocationList"
import CompanyDetails from "../components/CompanyDetails"
import { fetchCompany } from "../slices/company"
import { setInitialState } from "../../allocations/slices/allocations"

import TabPanel from "../../../core/components/Tabs"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

interface ParamType {
  id: string
}

const CompanyPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams<ParamType>()
  const { loading, company, error } = useSelector(
    (state: RootState) => state.company
  )
  const [value, setValue] = useState("updates")

  const componentWillUnmount = useRef(false)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    event.preventDefault()
    setValue(newValue)
  }

  const refreshPage = () => {
    window.location.reload() 
  }

  useEffect(() => {
    componentWillUnmount.current = true
    dispatch(fetchCompany({ id }))
  }, [dispatch])

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) dispatch(setInitialState())
    }
  }, [dispatch])

  if (loading || company == null) return <Loader />

  if (error) return <div className="errorMessageWrapper">
    Something went wrong...
    <br/>
    <button onClick={refreshPage}>
      Reload page
    </button>
  </div>

  return (
    <div className='pageWrapper companyPage'>
      <div className='pageBodyTopWrapper'>
        <CompanyDetails />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
        <div className='tabsWrapper'>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab value='idea' label='Idea' />
            <Tab value='team' label='Team' />
            <Tab value='updates' label='Updates' />
            <Tab value='valuation' label='Valuation' />
            <Tab value='investors' label='Investors' />
            <Tab value='documents' label='Documents' />
          </Tabs>
        </div>
          <TabPanel value={value} index='idea'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Idea section<br/>[WIP]
          </div>
        </TabPanel>
          <TabPanel value={value} index='team'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Team section<br/>[WIP]
          </div>
        </TabPanel>
          <TabPanel value={value} index='updates'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Updates section<br/>[WIP]
          </div>
        </TabPanel>
          <TabPanel value={value} index='valuation'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Valuation section<br/>[WIP]
          </div>
        </TabPanel>
          <TabPanel value={value} index='investors'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Investors section<br/>[WIP]
          </div>
        </TabPanel>
          <TabPanel value={value} index='documents'>
          <div className="warningMessageWrapper" style={{ backgroundColor: 'white'}}>
            Documents section<br/>[WIP]
          </div>
        </TabPanel>
        </div>
      </div>
    </div>
  )
}

export default CompanyPage
