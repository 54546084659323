import { Link } from "react-router-dom"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { Investor } from "../models/investor"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  investor: Investor
  maxValueWeight: number
}

export const InvestorListItemChart = ({ investor, maxValueWeight }: Props) => {
  return (
    <div className='grid-table-row listItem investorListItemChart'>
      <div className='grid-table-cell' data-title='Investor'>
        <Link to={`/investors/${investor.id}`}>
          {investor.firstName} {investor.lastName}
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Total Subscription'>
        <div className='barsWrapper'>
          <div className='bar barInvested' style={{ width: investor.totalSubscribed/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={investor.totalSubscribed ?? 0} />
            </div>
          </div>
          <div className='bar barValue' style={{ width: investor.totalSubscribed/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={investor.totalSubscribed ?? 0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
