import { MenuItem, TextField } from "@material-ui/core"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Type } from "../../../core/models/type"
import { RootState } from "../../../slices"
import { Fund } from "../../funds/models/fund"
import { setFilters } from "../slices/investments"

const InvestmentFilters = () => {
  const dispatch = useDispatch()
  const filters =
    useSelector((state: RootState) => state.investments.filters) ?? {}
  const { types } = useSelector((state: RootState) => state.investmentTypes)
  const { funds } = useSelector((state: RootState) => state.funds)

  const renderOptions = (types?: Type[]) => [
    <MenuItem key={0} value={0}>
      Any
    </MenuItem>,
    ...(types ?? []).map((type: Type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    )),
  ]

  const handleChange = (field: any, e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = e.target.value

    if (value === "") value = null
    if (value === 0) value = null

    dispatch(
      setFilters({
        ...(filters ?? {}),
        [field]: value,
      })
    )
  }

  return (
    <div className='filters investmentspageBodyTopWrapper'>
      <form>
        <div className='filtersInnerWrapper investmentFilters'>
          <div className='filtersColumn investmentsFiltersColumn column1'>
            <div className='header'>Search</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Company'
                variant='outlined'
                size='small'
                value={filters.search ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("search", e)
                }
              />
            </div>
          </div>
          <div className='filtersColumn investmentsFiltersColumn column2'>
            <div className='header'>Status</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Investment Status'
                variant='outlined'
                size='small'
                value={filters.state ?? 0}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("state", e)
                }
              >
                {renderOptions(types?.investmentStates)}
              </TextField>
            </div>
          </div>
          <div className='filtersColumn investmentsFiltersColumn column3'>
            <div className='header'>Tax Relief</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Any'
                variant='outlined'
                size='small'
                value={filters.tax_relief ?? 0}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("tax_relief", e)
                }
              >
                {renderOptions(types?.taxRelief)}
              </TextField>
            </div>
          </div>
          <div className='filtersColumn investmentsFiltersColumn column4'>
            <div className='header'>Fund</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Any'
                variant='outlined'
                size='small'
                value={filters.fund ?? 0}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("fund", e)
                }
              >
                {[
                  <MenuItem key={0} value={0}>
                    Any
                  </MenuItem>,
                  ...(funds ?? []).map((fund: Fund) => (
                    <MenuItem key={fund.id} value={fund.id}>
                      {fund.name}
                    </MenuItem>
                  )),
                ]}
              </TextField>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InvestmentFilters
