import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import logo from "../../assets/images/superseedLogo.svg"
import { RootState } from "../../slices"
import { setInitialState } from "../../features/user/slices/auth"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"

const menuItems = ["investors", "companies", "investments", "logout"]

export const Navbar = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { pathname } = useLocation()

  const user = useSelector((state: RootState) => state.auth.user)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(setInitialState())
  }

  return (
    <nav>
      <div className='logoWrapper'>
        <img src={logo} alt={"logo"} />
      </div>
      <div className='desktopMenuWrapper'>
        {user && (
          <>
            {menuItems.map(item =>
              item === "logout" ? (
                <Link key={item} to={() => ""} onClick={handleLogout}>
                  Logout
                </Link>
              ) : (
                <Link
                  key={item}
                  to={"/" + item}
                  className={pathname.includes(item) ? "selected" : ""}
                >
                  {item}
                </Link>
              )
            )}
          </>
        )}
      </div>
      <div className='mobileMenuWrapper'>
        <IconButton
          aria-label='more'
          aria-controls='mobile-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='mobile-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          {menuItems.map(item =>
            item === "logout" ? (
              <Link key={item} to={() => ""} onClick={handleLogout}>
                <MenuItem key={item} onClick={handleClose}>
                  Logout
                </MenuItem>
              </Link>
            ) : (
              <Link
                key={item}
                to={"/" + item}
                className={pathname.includes(item) ? "selected" : ""}
              >
                <MenuItem key={item} onClick={handleClose}>
                  {item}
                </MenuItem>
              </Link>
            )
          )}
        </Menu>
      </div>
    </nav>
  )
}
