import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Investor, InvestorFilters } from "../models/investor"
import {
  baseHeaders,
  parseOrdering,
  processResponse,
} from "../../../helpers/requests"
import { Sort } from "../../../core/models/sort"
import { RootState } from "../../../slices"

interface InvestorsState {
  results: Investor[]
  count?: number
  next?: string
  previous?: string
  page: number
  sort: Sort
  filters: InvestorFilters
  totalInvested?: number
  totalInvestable?: number
  totalSubscribed?: number
  loading: boolean
  loadingTable: boolean
  error?: string
}

interface FetchInvestorsProp {
  sort?: Sort
  page?: number
  filters?: InvestorFilters
}

const initialState: InvestorsState = {
  results: [],
  loading: true,
  loadingTable: false,
  page: 1,
  filters: {
    search: null,
    total_invested_from: null,
    total_invested_to: null,
    investor_type: null,
  },
  sort: {
    field: "first_name,last_name",
    direction: "ASC",
  },
  error: undefined
}

// Async
export const fetchInvestors = createAsyncThunk(
  "investments/fetchInvestors",
  async (
    { page, sort, filters = initialState.filters }: FetchInvestorsProp,
    { rejectWithValue, dispatch, getState }
  ) => {

    !(getState() as RootState).investors.results.length && !Object.values(filters).some(x => x !== null && x !== '')
      ? dispatch(setLoading())
      : dispatch(setLoadingTable())

    sort = sort || (getState() as RootState).investors.sort

    let params: any = {}
    params.page = page ?? 1
    params.ordering = parseOrdering(sort)
    params = { ...params, ...filters }

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}users/`, {
        ...baseHeaders(),
        params,
      })

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investorsSlice = createSlice({
  name: "investors",
  initialState,
  reducers: {
    setInitialState: state => {
      state.results = []
      state.page = 1
      state.error = undefined
      state.filters = {
        search: null,
        total_invested_from: null,
        total_invested_to: null,
        investor_type: null,
      }
      state.sort = {
        field: "first_name,last_name",
        direction: "ASC",
      }
      state.loading = false
    },
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    setLoadingTable: state => {
      state.error = undefined
      state.loadingTable = true
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestors.fulfilled, (state, { payload }) => {
      state.results = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.totalInvested = payload.totalInvested
      state.totalInvestable = payload.totalInvestable
      state.totalSubscribed = payload.totalSubscribed
      state.loading = false
      state.loadingTable = false
      state.error = undefined
    })
    addCase(fetchInvestors.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
      state.loadingTable = false
    })
  },
})

// Actions generated from the slice
export const {
  setInitialState,
  setLoading,
  setLoadingTable,
  setPage,
  setSort,
  setFilters,
} = investorsSlice.actions

export default investorsSlice.reducer
