import { useSelector } from "react-redux"
import { SummaryItem } from "../../../core/components/SummaryItem"
import { SummaryItemWithSubtitles } from "../../../core/components/SummaryItemWithSubtitles"
import { formatCurrency } from "../../../helpers/numbers"
import { RootState } from "../../../slices"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"

export const InvestmentHighlights = () => {
  const { count, totalInvested, totalInvestable } = useSelector(
    (state: RootState) => state.investments
  )

  return (
    <div className='highlights'>
      <SummaryItem
        title='Total Investments'
        value={`${count ?? "-"}`}
        className='column1'
      />
      <SummaryItem
        title='Total Invested'
        type={AttributeType.Currency}
        value={totalInvested ?? 0.0}
        className='column2'
      />
      <SummaryItemWithSubtitles
        title='Portfolio'
        subtitles={{
          Value: "-", // "0.0"
          TVPI: "-", // "1.0x"
          IRR: "-", // "0.0%"
        }}
        className='column3'
      />
      <SummaryItem
        title='Total Investable'
        type={AttributeType.Currency}
        value={totalInvestable ?? 0.0}
        className='column4'
      />
    </div>
  )
}
