import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Type } from "../../../core/models/type"
import { MenuItem, TextField } from "@material-ui/core"
import { setFilters } from "../slices/investors"

import NumberFormat from 'react-number-format'

export const InvestorFilters = (props: any) => {
  const {
    filters,
    investorTypes
  } = props

  const dispatch = useDispatch()

  const renderOptions = (types?: Type[]) => [
    <MenuItem key={0} value={0}>
      Any
    </MenuItem>,
    ...(types ?? []).map((type: Type) => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    )),
  ]

  const handleChange = (field: any, e: string | React.ChangeEvent<HTMLInputElement> ) => {
    let value: any = (typeof e === 'string') ? e : e.target.value

    if (value === "") value = null
    if (value === 0) value = null

    dispatch(
      setFilters({
        ...(filters ?? {}),
        [field]: value,
      })
    )
  }

  return (
    <div className='filters investmentspageBodyTopWrapper'>
      <form>
        <div className='filtersInnerWrapper investorspageBodyTopWrapper'>
          <div className='filtersColumn investorsFiltersColumn column1'>
            <div className='header'>Search</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Investor Name'
                variant='outlined'
                size='small'
                value={filters.search ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("search", e)
                }
              />
            </div>
          </div>
          <div className='filtersColumn investorsFiltersColumn column2'>
            <div className='header'>Total Invested</div>
            <div className='inputWrapper inputNumberFormatted'>
              <NumberFormat
                placeholder='From'
                value={filters.total_invested_from ?? ""} 
                thousandSeparator={true} 
                prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("total_invested_from", values.value)
                }}
              />
              <div className='inputInnerWrapperSplitter'>&nbsp;</div>
              <NumberFormat
                placeholder='To'
                value={filters.total_invested_to ?? ""} 
                thousandSeparator={true} 
                prefix={'£'}
                onValueChange={ (values) => {
                  handleChange("total_invested_to", values.value)
                }}
              />
            </div>
          </div>
          <div className='filtersColumn investorsFiltersColumn column3'>
            <div className='header'>Type</div>
            <div className='inputWrapper'>
              <TextField
                placeholder='Any'
                variant='outlined'
                size='small'
                value={filters.investor_type ?? 0}
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("investor_type", e)
                }
              >
                {renderOptions(investorTypes)}
              </TextField>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
