import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../core/components/Loader"
import { RootState } from "../../../slices"
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid'
import { Investment } from "../models/investment"
import { Subscription } from "../../subscriptions/models/subscription"

import { fetchAllSubscriptions, resetSubscriptionError } from "../../subscriptions/slices/subscriptions"
import { createBulkAllocation, resetAllocationError } from "../../../features/allocations/slices/allocations"

import { TextButton } from "../../../core/components/TextButton"

import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { formatCurrency, formatNumber } from "../../../helpers/numbers"

import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface IProps {
  investment: Investment
}

interface ISubmitValues {
  investment: string | number
  investmentAmount: string | number
  shares: string | number
  subscription: string | number
  taxRelief: string | number
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 0, hide: true },
  {
    field: 'name',
    headerName: 'Investor',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
  },
  {
    field: 'investmentAmount',
    headerName: 'Investment Amount',
    type: 'number',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    valueFormatter: (params: any) => formatCurrency(params.value)
  },
  {
    field: 'totalInvested',
    headerName: 'Total Invested',
    type: 'number',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    valueFormatter: (params: any) => formatCurrency(params.value)
  },
  {
    field: 'totalInvestable',
    headerName: 'Total Investable',
    type: 'number',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    valueFormatter: (params: any) => formatCurrency(params.value)
  },
  {
    field: 'projectedInvestment',
    headerName: 'Projected Investment',
    type: 'number',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    cellClassName: 'projectedInvestment',
    valueFormatter: (params: any) => (params.getValue(params.id, 'totalInvestable') > params.value)
      ? formatCurrency(params.value)
      : params.value
        ? params.getValue(params.id, 'totalInvestable')
        : '-'
  },
]

export const BulkActions = ({ investment }: IProps) => {
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [totalFundSubscription, setTotalFundSubscription] = useState<number | undefined>()
  const [totalMaxFundSubscription, setTotalMaxFundSubscription] = useState(false)
  const [selection, setSelection] = useState<number[]>([])
  const [allocated, setAllocated] = useState(0)

  const {
    loading,
    sort,
    error
  } = useSelector((state: RootState) => state.subscriptions)

  const [allSubscriptions, setAllSubscriptions] = useState(
    useSelector((state: RootState) => state.subscriptions.allSubscriptions) ?? [{ 
      'id': null,
      'name': '',
      'investmentAmount': null,
      'totalInvested': null,
      'totalInvestable': null,
      'projectedInvestment': null,
    }],
  )

  const handleClickOpenModal = () => {
    dispatch(fetchAllSubscriptions({
      sort: {
        field: 'total_investable',
        direction: 'DESC'
      }}))
    setOpenModal(true)
  }

  const handleClickCloseModal= () => {
    setOpenModal(false)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleSubmit = () => { 
    let selected: any = []
    selected = allSubscriptions
    .filter((el: Subscription) => selection.includes(el.id ?? 0))
    .map((el: any) => ({
      'investment': investment.id,
      'investmentAmount': el.projectedInvestment,
      'shares': 0,
      'subscription': el.id,
      'taxRelief': el.type
    }))
    dispatch(createBulkAllocation(selected))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
    setTotalMaxFundSubscription(false)
    let value = Number(e.target.value)
    if (value > 100) {
      setTotalMaxFundSubscription(true)
      return
    }
    setTotalFundSubscription(value || undefined)
    updateAllSubscriptions(value)
  }

  const handleSelection = (val: any) => {
    setSelection(val)
    updateAllSubscriptions(totalFundSubscription, val)
  }

  const updateAllSubscriptions = (val: number | undefined, arraySelect = selection) => {
    let value = val ?? 0
    let resultPercentage: number = 0

    let updatedAllSubscriptions = allSubscriptions.map( function(el: any) {
      
      let id: number = el.id
      let select: Array<number> = arraySelect

      const result:boolean = select.includes(id)

      let maxInvestable = Number(
        ((el.investmentAmount * value / 100) <= el.totalInvestable)
          ? (el.investmentAmount * value / 100)
          : el.totalInvestable
        )

      if (result) resultPercentage = resultPercentage + maxInvestable

      if (result) {
        return ({...el, ...{
          'projectedInvestment': maxInvestable
          }
        })
      }

      return ({...el, ...{
        'projectedInvestment': 0
        }
      })
    })

    setAllocated(resultPercentage)
    resultPercentage = 0
    setAllSubscriptions(updatedAllSubscriptions)
  }

  useEffect(() => {
    if (!loading) {
      if (error === 'Rejected') handleClickAlert()
    }
    dispatch(resetSubscriptionError())
  }, [dispatch, sort, loading, error, resetSubscriptionError])

  return (
    <div className='actionsModalWindow'>
      <div className='addNewButton'>
        <a onClick={handleClickOpenModal}>
          Bulk actions
        </a>
      </div>

      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          There was a network error. Please try again later.
        </Alert>
      </Snackbar>

      <Dialog open={openModal} onClose={handleClickCloseModal} aria-labelledby="form-dialog-title" className='actionsBottomMenuInvestments' maxWidth="lg">

        {
          loading 
          ? <Loader classStyle="modalWindow"/>
          : <div>
              <DialogContent>
              <div className='formContainer modalWindowContianer attributesContainer'>
                <div className='filtersColumn allocationsFiltersColumn column1'>
                  <div className='targetsWrapper'>
                    <div className='searchWrapper'>
                      <div className='header'>
                        % total fund subscription
                        <span className={`totalMaxFundSubscription ${ totalMaxFundSubscription ? 'maxReached' : ''}`}>
                          &nbsp;(Max. 100%)
                        </span>
                      </div>
                      <div className='inputWrapper'>
                        <TextField
                          placeholder='%'
                          variant='outlined'
                          size='small'
                          type='number'
                          value={totalFundSubscription ?? ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className='space'></div>
                    <div className='targetInvestment'>
                      <div className='header'>Target investment</div> 
                      <div className='inputWrapper'>
                        <TooltipCurrency amount={investment.targetInvestment}/>
                      </div>
                    </div>
                    <div className='targetInvestmentAllocated'>
                      <div className='header'>Allocated</div> 
                      <div className='inputWrapper'>
                        <TooltipCurrency amount={investment.company.amountInvested}/>
                      </div>
                    </div>
                    <div className='targetInvestmentAllocation'>
                      <div className='header'>New allocation</div> 
                      <div className={`inputWrapper 
                        ${(investment.targetInvestment - investment.company.amountInvested) < allocated 
                        ? 'maxExceed'
                        : allocated}`}
                      >
                        <TooltipCurrency amount={allocated}/>                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className='columnsWrap' style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={allSubscriptions}
                    columns={columns}
                    getRowClassName={(params: GridRowParams) => {    
                      let tI: any = 0
                      let pI: any = 0
                      tI = params.getValue(params.id, 'totalInvestable')
                      pI = params.getValue(params.id, 'projectedInvestment')
                      if (Number(tI) === Number(pI)) return 'maxExceed'
                      return ''
                    }}
                    pageSize={5}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(newSelection: any) => {
                      handleSelection(newSelection.selectionModel)
                    }}
                  />
                </div>
              </div>
              </DialogContent>
              
              <DialogActions>
                <TextButton onClick={handleClickCloseModal}>
                  <span>Cancel</span>
                </TextButton>
                <TextButton onClick={handleSubmit}>
                  <button type="submit" disabled={
                    (investment.targetInvestment - investment.company.amountInvested) <= allocated ||
                    allocated === 0
                  }>
                    Submit
                  </button>
                </TextButton>
              </DialogActions>

            </div>
        }
      </Dialog>
    </div>
  )
}
