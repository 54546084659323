import {
  AttributeItem,
  AttributeType,
} from "./AttributeItem"

interface IProps {
  title: string
  item?: string
  value: any
  type?: AttributeType
  className?: string
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const SummaryItem = ({
  title, 
  value, 
  type,
  className, 
  readOnly = true,
  item = '',
  values, 
  handleChange, 
  errors, 
  touched }: IProps) => {

  return (
    <div className={`highlightsColumn ${className ?? ""}`}>
      <AttributeItem
        header={title}
        value={value}
        type={type ||  AttributeType.Number || AttributeType.Null}
        readOnly={readOnly}
        item = {item}
        values = {values}
        handleChange = {handleChange}
        errors = {errors}
        touched = {touched}
      />
    </div>
  )

}
