import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosRequestConfig } from "axios"
import { Sort } from "../../../core/models/sort"
import { baseHeaders, processResponse } from "../../../helpers/requests"
import { RootState } from "../../../slices"
import { Fund } from "../models/fund"

interface FundsState {
  funds: Fund[]
  loading: boolean
  error?: string
}

const initialState: FundsState = {
  funds: [],
  loading: true,
  error: undefined
}

// Async
export const fetchFunds = createAsyncThunk(
  "funds/fetchFunds",
  async ({}: any, { rejectWithValue, dispatch, getState }) => {
    dispatch(setLoading())

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_HOST}funds/`, {
        ...baseHeaders(),
      })

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchFunds.fulfilled, (state, { payload }) => {
      state.funds = payload.results
      state.loading = false
      state.error = undefined
    })
    addCase(fetchFunds.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading } = fundsSlice.actions

export default fundsSlice.reducer
