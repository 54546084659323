import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { RootState } from "../../../slices"

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import { Application, ApplicationTypes } from "../models/application"
import { formatCurrency } from "../../../helpers/numbers"
import { getTypeById } from "../../../helpers/types"

interface RouteParams {
  id: string
  applicationId: string
}

enum Types {
  TEXT = "text",
  DATE = "date",
  CURRENCY = "currency",
  TYPE = "type",
  BOOLEAN = "boolean",
  CUSTOM = "custom",
}
interface Field {
  field: string
  type: Types
  typeField?: string
  name?: string
  render?: (application: Application, types?: ApplicationTypes) => any
}

interface Section {
  name: string
  fields: Field[]
}

const SECTIONS: Section[] = [
  {
    name: "Investor Details",
    fields: [
      {
        field: "title",
        type: Types.TYPE,
        typeField: "title",
      },
      {
        field: "firstname",
        type: Types.TEXT,
        name: "First Name",
      },
      {
        field: "lastname",
        type: Types.TEXT,
        name: "Last Name",
      },
      {
        field: "investorReference",
        type: Types.TEXT,
        name: "Investor Reference",
      },
      {
        field: "phone",
        type: Types.TEXT,
      },
      {
        field: "email",
        type: Types.TEXT,
      },
      {
        field: "dateOfBirth",
        type: Types.DATE,
        name: "Date of Birth",
      },
      {
        field: "address",
        type: Types.CUSTOM,
        name: "Address",
        render: application => {
          return (
            <span className='value'>
              <p>{`${application.address1}`}</p>
              <p>{`${application.address2}`}</p>
              <p>{`${application.postcode}`}</p>
              <p>{`${application.years ?? 0} years, ${
                application.months ?? 0
              } months.`}</p>
            </span>
          )
        },
      },
      {
        field: "previousAddress",
        type: Types.CUSTOM,
        name: "Previous Address",
        render: application => {
          if (application.previousAddress !== "")
            return (
              <>
                <p className='value'>{`${application.previousAddress}`}</p>
                <p className='value'>{`${application.previousAddress2}`}</p>
                <p className='value'>{`${application.previousAddressPostcode}`}</p>
              </>
            )
          return <p className='value'> - </p>
        },
      },
    ],
  },
  {
    name: "Tax Relief",
    fields: [
      {
        field: "Claim Relief",
        name: "Claim Relief",
        type: Types.CUSTOM,
        render: (application: Application) => {
          let relief: string = "Don't claim relief"
          if (application.claimEisRelief) relief = "Claim EIS Relief"
          if (application.claimSeisRelief) relief = "Claim SEIS Relief"
          return <p className='value'>{relief}</p>
        },
      },
      {
        field: "countries",
        type: Types.TEXT,
      },
    ],
  },
  {
    name: "Investment Background",
    fields: [
      {
        field: "eisInvestments",
        type: Types.CUSTOM,
        name: "EIS Investments",
        render: (application: Application) => {
          if (application.eisInvestments)
            return (
              <p className='value'>{`${
                application.eisInvestmentsYears ?? 0
              } years`}</p>
            )
          return <p className='value'> - </p>
        },
      },
      {
        field: "unquotedInvestments",
        type: Types.CUSTOM,
        name: "Unquoted Investments",
        render: (application: Application) => {
          if (application.unquotedInvestments)
            return (
              <p className='value'>{`${
                application.unquotedInvestmentsYears ?? 0
              } years`}</p>
            )
          return <p className='value'> - </p>
        },
      },
      {
        field: "smallUnquotedInvestments",
        type: Types.CUSTOM,
        name: "Small Quoted Investments",
        render: (application: Application) => {
          if (application.smallQuotedInvestments)
            return (
              <p className='value'>{`${
                application.smallQuotedInvestmentsYears ?? 0
              } years`}</p>
            )
          return <p className='value'> - </p>
        },
      },
      {
        field: "largeQuotedInvestments",
        type: Types.CUSTOM,
        name: "Large Quoted Investments",
        render: (application: Application) => {
          if (application.largeQuotedInvestments)
            return (
              <p className='value'>{`${
                application.largeQuotedInvestmentsYears ?? 0
              } years`}</p>
            )
          return <p className='value'> - </p>
        },
      },
      {
        field: "techFundsInvestments",
        type: Types.CUSTOM,
        name: "Tech Funds Investments",
        render: (application: Application) => {
          if (application.techFundsInvestments)
            return (
              <p className='value'>{`${
                application.techFundsInvestmentsYears ?? 0
              } years`}</p>
            )
          return <p className='value'> - </p>
        },
      },
      {
        field: "regularInvestmentAmount",
        type: Types.TYPE,
        name: "Regular Investment Amount",
        typeField: "regularInvestmentAmount",
      },
      {
        field: "investmentFrequency",
        type: Types.TYPE,
        name: "Investment Frequency",
        typeField: "investmentFrequency",
      },
      {
        field: "unquotedInvestmentsLastTwoYears",
        type: Types.BOOLEAN,
        name: "Unquoted Companies in Last Two Years",
      },
      {
        field: "memberBusinessAngel",
        type: Types.BOOLEAN,
        name: "Member Business Angel",
      },
      {
        field: "companyDirector",
        type: Types.BOOLEAN,
        name: "Company Director",
      },
      {
        field: "awareEarlyStageFailure",
        type: Types.BOOLEAN,
        name: "Aware of Early Stage Failure",
      },
      {
        field: "awareLoseCapital",
        type: Types.BOOLEAN,
        name: "Aware of Loss of Capital",
      },
      {
        field: "awareNotReadilyRealisable",
        type: Types.BOOLEAN,
        name: "Aware Funds Aren't Readily Realisable",
      },
      {
        field: "educationLevel",
        type: Types.TYPE,
        name: "Education Level",
        typeField: "educationLevel",
      },
      {
        field: "occupation",
        type: Types.TEXT,
      },
      {
        field: "holdFinantialQualification",
        type: Types.BOOLEAN,
        name: "Holds Financial Qualification",
      },
      {
        field: "holdProfessionalRole",
        type: Types.BOOLEAN,
        name: "Holds Professional Role",
      },
      {
        field: "holdFinancialRole",
        type: Types.BOOLEAN,
        name: "Holds Financial Role",
      },
      {
        field: "bearLosses",
        type: Types.BOOLEAN,
        name: "Can Bear Losses",
      },
      {
        field: "incomeBracket",
        type: Types.TYPE,
        name: "Income Bracket",
        typeField: "incomeBracket",
      },
      {
        field: "assetBracket",
        type: Types.TYPE,
        name: "Asset Bracket",
        typeField: "assetBracket",
      },
      {
        field: "investmentFundSource",
        type: Types.CUSTOM,
        name: "Investment Fund Source",
        render: (application, types) => {
          if (types === undefined) return "No source."

          const source = getTypeById(
            types.investmentFundSource,
            application.investmentFundSource
          )

          if (source?.name === "Other")
            return (
              <p className='value'>{`Other - ${application.investmentFundSourceOther}`}</p>
            )

          return <p className='value'>{source?.name ?? "No source."}</p>
        },
      },
    ],
  },
  {
    name: "Investment Details",
    fields: [
      {
        field: "investmentAmount",
        type: Types.CURRENCY,
        name: "Investment Amount",
      },
      {
        field: "fundTransferMethod",
        type: Types.TYPE,
        name: "Fund Transfer Method",
        typeField: "fundTransferMethod",
      },
      {
        field: "acceleratedDeployment",
        type: Types.BOOLEAN,
        name: "Accelerated Deployment",
      },
      {
        field: "acceleratedDeploymentAcknowledge",
        type: Types.BOOLEAN,
        name: "Accelerated Deployment Acknowledge",
      },
      {
        field: "confirmedAt",
        type: Types.DATE,
        name: "Submitted",
      },
      {
        field: "hasAgreedToManagementPolicy",
        type: Types.BOOLEAN,
        name: "Accepted Acknowledgments and Representations",
      },
      {
        field: "fundsClearedDate",
        type: Types.DATE,
        name: "Funds Cleared Date",
      },
      {
        field: "amlDate",
        type: Types.DATE,
        name: "AML Date",
      },
      {
        field: "isCoinvestment",
        type: Types.CUSTOM,
        name: "Coinvestment",
        render: application => {
          if (application.isCoinvestment)
            return (
              <p className='value'>{`Yes - ${
                application.allocateToCompany ?? "Unnamed"
              }`}</p>
            )
          return <p className='value'>No</p>
        },
      },
      {
        field: "applicationPdf",
        type: Types.CUSTOM,
        name: "PDF",
        render: application => {
          if (application.applicationPdf)
            return (
              <a
                target='_blank'
                rel='noreferrer'
                href={application.applicationPdf}
              >
                Download
              </a>
            )
          return <p className='value'> - </p>
        },
      },
    ],
  },
]

export const ApplicationDialog = () => {
  const { id, applicationId } = useParams<RouteParams>()
  const { applications } = useSelector((state: RootState) => state.applications)
  const { types } = useSelector((state: RootState) => state.applicationTypes)

  const application = applications.find(
    application => application.id === parseInt(applicationId)
  )

  const renderField = ({ field, type, typeField, render, name }: Field) => {
    let value: string = ""

    if (application !== undefined) {
      if (type === Types.TEXT) value = application[field]
      if (type === Types.DATE) value = application[field]
      if (type === Types.CURRENCY) value = formatCurrency(application[field])
      if (type === Types.TYPE)
        value =
          (types &&
            typeField &&
            getTypeById(types[typeField], application[field])?.name) ??
          " - "
      if (type === Types.BOOLEAN) value = application[field] ? "Yes" : "No"

      if (type === Types.CUSTOM && render)
        return (
          <div className='table-row'>
            <span className='attribute'>{name ?? field}</span>
            {render(application, types)}
          </div>
        )
    }

    return (
      <div className='table-row'>
        <span className='attribute'>{name ?? field}</span>
        <span className='value'>{value}</span>
      </div>
    )
  }

  const renderSection = (section: Section) => {
    return (
      <div className='section'>
        <div className='section-header'>{section.name}</div>
        {section.fields.map(renderField)}
      </div>
    )
  }

  return (
    <div className='application-dialog'>
      <div className='dialog'>
        <Link to={`/investors/${id}`}>
          <CloseOutlinedIcon />
        </Link>
        <div className='application-table'>{SECTIONS.map(renderSection)}</div>
      </div>
    </div>
  )
}
