import { useSelector } from "react-redux"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { Type } from "../../../core/models/type"
import { getTypeById } from "../../../helpers/types"
import { RootState } from "../../../slices"
import { Investment } from "../models/investment"

interface IProps {
  investment?: Investment
  item?: string
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const InvestmentAttributes = ({
  investment,
  readOnly = true,
  item = "",
  values,
  handleChange,
  errors,
  touched,
}: IProps) => {
  const { types } = useSelector((state: RootState) => state.investmentTypes)

  return (
    <div className='attributesContainer'>
      <div className='attributeGrid'>
        <AttributeItem
          header='Share Price'
          value={investment?.sharePrice}
          type={AttributeType.Number}
          inputType='text'
          readOnly={readOnly}
          item='sharePrice'
          values={values.sharePrice}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Target Investment'
          value={investment?.targetInvestmentDate}
          type={AttributeType.Date}
          inputType='date'
          readOnly={readOnly}
          item='targetInvestmentDate'
          values={values.targetInvestmentDate}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Nominal Value'
          value={investment?.nominalPrice}
          type={AttributeType.Number}
          inputType='text'
          readOnly={readOnly}
          item='nominalPrice'
          values={values.nominalPrice}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Valuation (pre-money)'
          // value={investment?.valuationPreMoney}
          value={'-'}
          type={AttributeType.Text}
          inputType='text'
          readOnly={true}
          item='valuationPreMoney'
          values={values.valuationPreMoney}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Share Type'
          value={
            types?.shareTypes.find(
              (state: Type) => state.id === investment?.shareType
            )?.name
          }
          type={AttributeType.Number || AttributeType.Null}
          inputType='select'
          readOnly={readOnly}
          item = 'shareType'
          values = {values.shareType}
          arrayOfValues = {
            types?.shareTypes.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Shares Offered'
          // value={investment?.sharesOffered}
          value={'-'}
          type={AttributeType.Text}
          inputType='text'
          readOnly={true}
          item='sharesOffered'
          values={values.sharesOffered}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <AttributeItem
          header='Tax Relief'
          value={getTypeById(types?.taxRelief, investment?.taxRelief)?.name}
          type={AttributeType.Text}
          inputType='select'
          readOnly={readOnly}
          item = 'taxRelief'
          values = {values.taxRelief}
          arrayOfValues = {
            types?.taxRelief.reduce<Record<string, number>>(function (obj, item: Type) {
              obj[item.name] = item.id
              return obj
            }, {})
          }
          handleChange = {handleChange}
          errors = {errors}
          touched = {touched}
        />
        <AttributeItem
          header='Valuation (post-money)'
          // value={investment?.valuationPostMoney}
          value={'-'}
          type={AttributeType.Text}
          inputType='text'
          readOnly={true}
          item='valuationPostMoney'
          values={values.valuationPostMoney}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </div>
    </div>
  )
}
