import { useSelector } from "react-redux"
import {
  AttributeItem,
  AttributeType,
} from "../../../core/components/AttributeItem"
import { RootState } from "../../../slices"
import { Investor } from "../models/investor"

interface Props {
  investor?: Investor
  readOnly?: boolean
  values?: any
  handleChange?: any
  errors?: any
  touched?: any
}

export const InvestorDescription = ({
  investor,
  readOnly = true,
  values, 
  handleChange, 
  errors, 
  touched }: Props) => {

  return (
    <div className='investorDescriptionContainer'>
      <div className='left'>{investor?.photo && <img alt="" src={investor?.photo} />}</div>
      <div className='right'>
        <h1 className='investorName'>
          <AttributeItem
            header=''
            value={investor?.firstName}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'firstName'
            values = {values.firstName}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
          <div className="space"></div>
          <AttributeItem
            header=''
            value={investor?.lastName}
            type={AttributeType.Text}
            readOnly={readOnly}
            item = 'lastName'
            values = {values.lastName}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
        </h1>
        <div className='investorDescription'>
          <AttributeItem
            header=''
            value={investor?.bio}
            type={AttributeType.TextArea}
            inputType='textarea'
            readOnly={readOnly}
            item = 'bio'
            values = {values.bio}
            handleChange = {handleChange}
            errors = {errors}
            touched = {touched}
          />
        </div>
      </div>
    </div>
  )
}
