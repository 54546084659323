import { Link } from "react-router-dom"
import { formatCurrency, formatNumber } from "../../../helpers/numbers"
import { Investment } from "../models/investment"
import TooltipCurrency from "../../../core/components/tooltipCurrency"

interface Props {
  investment: Investment
  maxValueWeight: number
}

export const InvestmentListItemChart = ({ investment, maxValueWeight }: Props) => {
  return (
    <div className='grid-table-row listItem investmentListItemChart'>
      <div className='grid-table-cell' data-title='Investment'>
        <Link to={`/investments/${investment.id}`}>
          {investment.company.name}
        </Link>
      </div>
      <div className='grid-table-cell' data-title='Total Subscription'>
        <div className='barsWrapper'>
          <div className='bar barInvested' style={{ width: investment.currentlyAllocated/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={investment.currentlyAllocated ?? 0} />
            </div>
          </div>
          <div className='bar barValue' style={{ width: investment.targetInvestment/Math.pow(10, maxValueWeight-2) * (1 - 0.1)+'%'}}>
            <div className="value">
              <TooltipCurrency amount={investment.targetInvestment ?? 0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
