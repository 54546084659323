import { isArray } from "./isArray"
import { isObject } from "./isObject"

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace("-", "").replace("_", "")
  })
}

interface LooseObject {
  [key: string]: any
}

export const keysToCamel = function (o: any) {
  if (isObject(o)) {
    const n: LooseObject = {}

    Object.keys(o).forEach((k: string) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: number) => {
      return keysToCamel(i)
    })
  }

  return o
}

