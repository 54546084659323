import axios, { AxiosResponse, AxiosRequestConfig } from "axios"
import Axios from "axios"
import { Sort } from "../core/models/sort"
import { keysToCamel } from "./toCamel"

export const processResponse = (res: AxiosResponse) => {
  if (axios.isCancel(res)) {
    throw new Axios.Cancel("Request was cancelled")
  }
  return keysToCamel(res.data)
}

export function baseHeaders(extraHeaders?: object): AxiosRequestConfig {
  const state = JSON.parse(localStorage.getItem("state") || "{}")
  const token = (state["auth"] || {})["token"] || ""
  const cancelToken = axios.CancelToken.source()

  return {
    cancelToken: cancelToken.token,
    headers: {
      ...extraHeaders,
      Authorization: `Token ${token}`,
    },
  }
}

export function getErrorCode(error: any): number {
  return (error && error.response && error.response.status) || 0
}

export const parseOrdering = (sort?: Sort) => {
  if (!sort) return null

  const prefix = sort?.direction === "DESC" ? "-" : ""
  return sort?.field
    .split(",")
    .map((fieldPart: string) => `${prefix}${fieldPart}`)
    .join(",")
}
