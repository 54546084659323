import { InvestorListItemChart } from "./InvestorListItemChart"
import { Investor } from "../models/investor"
import { xAxisTable } from "../../../helpers/xAxisTableChart"
import { formatCurrency } from "../../../helpers/numbers"

interface IProps {
  investors: any
  maxValue: number
}

export const InvestorChart = ({ investors, maxValue }: IProps) => {
  return (
    <div className='tableInvestorListChart'>
      <div className='grid-table'>
        <div className='grid-table-row'>
          <div className='grid-table-cell'>Investor</div>
          <div className='grid-table-cell'>
            { xAxisTable(maxValue).map((x: number) => (
              <span className='yaxis'key={x}>
                { formatCurrency(x, true, true, true) }
              </span>
            )) }
          </div>
        </div>

        {investors.map((investor: Investor) => (
          <InvestorListItemChart 
            key={investor.id} 
            investor={investor} 
            maxValueWeight={String(Math.trunc( maxValue )).length}/>
        ))}
      </div>
    </div>
  )
}
