import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons"

import { Sort } from "../models/sort"

interface IProps {
  value: string
  field?: string
  sort?: Sort
  onSort: (sort: Sort) => void
}

export const TableHeader = ({ value, field, sort, onSort }: IProps) => {
  if (!field) return <div className='grid-table-cell no-sort'>{value}</div>

  if (!!sort && sort?.field === field)
    return (
      <div
        className='grid-table-cell sortable'
        onClick={() =>
          onSort({
            field: field,
            direction: sort.direction === "DESC" ? "ASC" : "DESC",
          })
        }
      >
        {sort.direction === "ASC" ? <ArrowDropDown /> : <ArrowDropUp />} {value}
      </div>
    )

  return (
    <div
      className='grid-table-cell sortable'
      onClick={() =>
        onSort({
          field: field,
          direction: "ASC",
        })
      }
    >
      {value}
    </div>
  )
}
