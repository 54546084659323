import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { InvestmentTypes } from "../models/investment"
import { baseHeaders, processResponse } from "../../../helpers/requests"

interface InvestmentTypeState {
  types?: InvestmentTypes
  loading: boolean
  error?: string
}

const initialState: InvestmentTypeState = {
  loading: true,
}

// Async
export const fetchInvestmentTypes = createAsyncThunk(
  "investment/fetchInvestmentTypes",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setLoading())

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}investments/types/`,
        baseHeaders()
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return processResponse(res)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const investmentTypesSlice = createSlice({
  name: "investmentTypes",
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchInvestmentTypes.fulfilled, (state, { payload }) => {
      state.types = payload
      state.loading = false
    })
  },
})

// Actions generated from the slice
export const { setLoading } = investmentTypesSlice.actions

export default investmentTypesSlice.reducer
