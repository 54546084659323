import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  AllocationList,
  TableMode,
} from "../../allocations/components/AllocationList"
import investor, { fetchInvestor } from "../slices/investor"
import InvestorDetails from "../components/InvestorDetails"

import TabPanel from "../../../core/components/Tabs"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { PortfolioList } from "../../portfolio/components/PortfolioList"
import { SubscriptionList } from "../../subscriptions/components/SubscriptionList"
import { fetchApplicationTypes } from "../../applications/slices/applicationTypes"
import { fetchSubscriptionTypes } from "../../subscriptions/slices/subscriptionTypes"
import { ApplicationList } from "../../applications/components/ApplicationList"
import { RootState } from "../../../slices"
import { Loader } from "../../../core/components/Loader"

interface ParamType {
  id: string
}

const InvestorPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams<ParamType>()
  const { loading, investor, error } = useSelector(
    (state: RootState) => state.investor
  )
  const [value, setValue] = React.useState("allocations")

  useEffect(() => {
    dispatch(fetchInvestor({ id }))
    dispatch(fetchApplicationTypes())
    dispatch(fetchSubscriptionTypes())
  }, [dispatch])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    event.preventDefault()
    setValue(newValue)
  }

  const refreshPage = () => {
    window.location.reload() 
  }

  if (loading || investor == null) return <Loader />

  if (error) return <div className="errorMessageWrapper">
    Something went wrong...
    <br/>
    <button onClick={refreshPage}>
      Reload page
    </button>
  </div>

  return (
    <div className='pageWrapper investorPage'>
      <div className='pageBodyTopWrapper'>
        <InvestorDetails />
      </div>
      <div className='pageBodyBottomWrapper'>
        <div className='pageBodyBottomInnerWrapper'>
          <div className='tabsWrapper'>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab value='applications' label='Applications' />
              <Tab value='subscriptions' label='Subscriptions' />
              <Tab value='allocations' label='Allocations' />
              <Tab value='portfolio' label='Portfolio' />
            </Tabs>
          </div>
          <TabPanel value={value} index='applications'>
            <ApplicationList investor={parseInt(id)} />
          </TabPanel>
          <TabPanel value={value} index='subscriptions'>
            <SubscriptionList investor={parseInt(id)} />
          </TabPanel>
          <TabPanel value={value} index='allocations'>
            <AllocationList mode={TableMode.Investor} investor={investor} />
          </TabPanel>
          <TabPanel value={value} index='portfolio'>
            <PortfolioList investor={parseInt(id)} />
          </TabPanel>
        </div>
      </div>
    </div>
  )
}

export default InvestorPage
