import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Company, CompanyFilters } from "../models/company"
import {
  baseHeaders,
  parseOrdering,
  processResponse,
} from "../../../helpers/requests"
import { Sort } from "../../../core/models/sort"
import { RootState } from "../../../slices"

interface CompaniesState {
  allCompanies: Company[]
  results: Company[]
  count?: number
  next?: string
  previous?: string
  page: number
  sort: Sort
  filters: CompanyFilters
  totalInvested?: number
  totalInvestable?: number
  totalSubscribed?: number
  loading: boolean
  loadingTable: boolean
  error?: string
}

const initialState: CompaniesState = {
  allCompanies: [],
  results: [],
  loading: true,
  loadingTable: false,
  page: 1,
  sort: {
    field: "name",
    direction: "ASC",
  },
  filters: {
    search: null,
    total_invested_from: null,
    total_invested_to: null,
    type: null,
  },
  error: undefined
}

interface FetchCompaniesProp {
  sort?: Sort
  page?: number
  filters?: CompanyFilters
}

// Async
export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async (
    { page, sort, filters = initialState.filters }: FetchCompaniesProp,
    { rejectWithValue, dispatch, getState }
  ) => {

    !(getState() as RootState).companies.results.length && !Object.values(filters).some(x => x !== null && x !== '')
      ? dispatch(setLoading())
      : dispatch(setLoadingTable())

    sort = sort || (getState() as RootState).companies.sort

    let params: any = {}
    params.page = page ?? 1
    params.ordering = parseOrdering(sort)
    params = { ...params, ...filters }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}companies/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res), page: page ?? 1 }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Async
export const fetchAllCompanies = createAsyncThunk(
  "companies/fetchAllCompanies",
  async (
    { sort }: FetchCompaniesProp,
    { rejectWithValue, dispatch, getState }
  ) => {

    if (!(getState() as RootState).companies.results.length) dispatch(setLoading())

    sort = sort || (getState() as RootState).companies.sort

    let params: any = {
      page: 1,
      total_investable_from: 1,
      page_size: 10000
    }
    params.ordering = parseOrdering(sort)

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_HOST}companies/`,
        {
          ...baseHeaders(),
          params,
        }
      )

      if (res.status === 500) {
        return rejectWithValue("Something went wrong.")
      } else if (res.status !== 200) {
        return rejectWithValue(res.statusText)
      }

      return { ...processResponse(res) }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setInitialState: state => {
      state.results = []
      state.page = 1
      state.error = undefined
      state.sort = {
        field: "name",
        direction: "ASC",
      }
      state.filters = {
        search: null,
        total_invested_from: null,
        total_invested_to: null,
        type: null,
      }
      state.loading = false
    },
    setLoading: state => {
      state.error = undefined
      state.loading = true
    },
    setLoadingTable: state => {
      state.error = undefined
      state.loadingTable = true
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload.sort
      state.page = payload.page
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.results = payload.results
      state.count = payload.count
      state.next = payload.next
      state.previous = payload.previous
      state.page = payload.page
      state.totalInvested = payload.totalInvested
      state.totalInvestable = payload.totalInvestable
      state.totalSubscribed = payload.totalSubscribed
      state.loading = false
      state.loadingTable = false
      state.error = undefined
    })
    addCase(fetchCompanies.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
      state.loadingTable = false
    })
    addCase(fetchAllCompanies.fulfilled, (state, { payload }) => {
      state.allCompanies = payload.results
      state.loading = false
      state.loadingTable = false
      state.error = undefined
    })
    addCase(fetchAllCompanies.rejected, (state, { error }) => {
      state.error = error.message
      state.loading = false
      state.loadingTable = false
    })
  },
})

// Actions generated from the slice
export const {
  setInitialState,
  setLoading,
  setLoadingTable,
  setPage,
  setSort,
  setFilters,
} = companiesSlice.actions

export default companiesSlice.reducer
