import { Link } from "react-router-dom"
import { Company } from "../../companies/models/company"

interface IProps {
  company?: Company
}

export const InvestmentCompany = ({ company }: IProps) => {
  const getSectors = () => {
    const sectors = company?.sectors ?? []

    if (sectors?.length === 0) return "No sectors"

    return sectors?.map(sector => sector.name).join(" | ")
  }

  return (
    <div className='investmentCompanyContainer'>
      <div className='left'>{company?.logo && <img src={company.logo} />}</div>
      <div className='right'>
        <h1 className='companyName'>{company?.name ?? ""}</h1>
        <p className='companySector'>{getSectors()}</p>
        <Link className='companyLink' to={`/companies/${company?.id}`}>
          View company
        </Link>
      </div>
    </div>
  )
}
